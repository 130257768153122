import React from "react";

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  IconButton,
  Box,
  Button,
  useToast,
  MenuItem,
} from "@chakra-ui/react";

import { FiTrash2 } from "react-icons/fi";

import { updateClientActive } from "../../../api/client/client";
import { useMutation, useQueryClient } from "react-query";

const DisactiveClientDialog = (props) => {
  const { id, is_active } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = React.useRef();
  const toast = useToast();
  const queryClient = useQueryClient();

  const activeMutation = useMutation(updateClientActive, {
    onSuccess: () => {
      toast({
        title: "Sukces!",
        position: "top-right",
        description: "Status aktywności został zmieniony!",
        status: "success",
        duration: 4000,
        isClosable: true,
      });

      onClose();
      queryClient.invalidateQueries("fetchClient");
    },
    onError: () =>
      toast({
        title: "Uwaga, wystapił problem",
        position: "top-right",
        description: "Spróbuj ponownie później!",
        status: "error",
        duration: 4000,
        isClosable: true,
      }),
  });

  const handleChange = () => {
    activeMutation.mutate({ id: id, is_active: !is_active });
    onClose();
  };

  return (
    <>
      <MenuItem onClick={() => setIsOpen(true)}>
        {is_active == 1 ? "Dezaktywuj klienta" : "Aktywuj klienta"}
      </MenuItem>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {is_active == 1 ? "Dezaktywuj klienta" : "Aktywuj klienta"}
            </AlertDialogHeader>

            <AlertDialogBody>Czy chcesz to zrobić na pewno?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Anuluj
              </Button>
              <Button
                colorScheme="red"
                onClick={() => handleChange()}
                ml={3}
                isLoading={activeMutation.isLoading}
              >
                {is_active == 1 ? "Dezaktywuj klienta" : "Aktywuj klienta"}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default DisactiveClientDialog;
