import axios from "axios";
import { getToken } from "../../utils/GetToken";
import { API } from "../../utils/GetSystemVersion";

export const fetchCategories = async () => {
  const token = getToken();

  const { data } = await axios.get(`${API()}/community/questions/categories`, {
    headers: { Authorization: `Token ${token}` },
  });
  return data;
};

export const fetchCategory = async (params) => {
  const token = getToken();
  const id = params["queryKey"][1];

  const { data } = await axios.get(
    `${API()}/community/questions/category/${id}`,
    {
      headers: { Authorization: `Token ${token}` },
    }
  );
  return data;
};

export const storeQuestionCategory = async (values) => {
  const token = getToken();

  const form = new FormData();
  form.set("name", values.name);
  form.set("description", values.description);

  const { data } = await axios({
    method: "post",
    url: `${API()}/community/questions/category/create`,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${token}`,
    },
    data: form,
  });
  return data;
};

export const storeQuestion = async (values) => {
  const token = getToken();

  const form = new FormData();
  form.set("category", values.category);
  form.set("question", values.question);
  form.set("is_logical", values.is_logical);
  form.set("is_text", values.is_text);
  form.set("is_photo", values.is_photo);
  form.set("is_quickmail", values.is_quickmail);
  form.set("is_time_question", values.is_time_question);

  const { data } = await axios({
    method: "post",
    url: `${API()}/community/questions/create`,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${token}`,
    },
    data: form,
  });
  return data;
};

export const updateQuestion = async (values) => {
  const token = getToken();

  const form = new FormData();
  form.set("question", values.question);
  form.set("is_logical", values.is_logical);
  form.set("is_text", values.is_text);
  form.set("is_photo", values.is_photo);
  form.set("is_quickmail", values.is_quickmail);
  form.set("is_time_question", values.is_time_question);

  const { data } = await axios({
    method: "patch",
    url: `${API()}/community/questions/${values.pk}/update`,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${token}`,
    },
    data: form,
  });
  return data;
};

export const deleteQuestion = async (id) => {
  const token = getToken();

  const form = new FormData();

  const { data } = await axios({
    method: "delete",
    url: `${API()}/community/questions/${id}/delete`,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${token}`,
    },
  });
  return data;
};
