import React, { useState } from "react";

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Text,
  Divider,
  Flex,
  Button,
  useToast,
  MenuItem,
} from "@chakra-ui/react";

import { FiTrash2 } from "react-icons/fi";

import { storeClientAccess } from "../../../api/client/client";
import { useMutation, useQueryClient } from "react-query";

const AccessRequestDialog = (props) => {
  const { id } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const onClose = () => setIsOpen(false);

  const cancelRef = React.useRef();
  const toast = useToast();
  const queryClient = useQueryClient();

  const accessMutation = useMutation(storeClientAccess, {
    onSuccess: (response) => {
      toast({
        title: "Sukces!",
        position: "top-right",
        description: "Utworzono dostęp tymczasowy!",
        status: "success",
        duration: 4000,
        isClosable: true,
      });

      setUsername(response.username);
      setPassword(response.password);
    },
    onError: () =>
      toast({
        title: "Uwaga, wystapił problem",
        position: "top-right",
        description: "Spróbuj ponownie później!",
        status: "error",
        duration: 4000,
        isClosable: true,
      }),
  });

  const handleSubmit = () => {
    accessMutation.mutate({ id: id });
  };

  return (
    <>
      <MenuItem onClick={() => setIsOpen(true)}>
        Utwórz tymczasowy dostęp
      </MenuItem>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Utwórz tymczasowy dostęp
            </AlertDialogHeader>

            <AlertDialogBody>
              Czy chcesz to zrobić na pewno?
              {username && password && (
                <Flex d="column" py="4">
                  <Flex d="column">
                    <Text fontSize="xs" fontWeight="semibold" color="green.500">
                      Sukces!
                    </Text>
                    <Text fontWeight="semibold" fontSize="lg">
                      Dostęp został utworzony!
                    </Text>
                  </Flex>
                  <Flex d="column">
                    <Flex>
                      <Text
                        fontSize="sm"
                        fontWeight="semibold"
                        mr="2"
                        color="gray.700"
                      >
                        Nazwa użytkownika:
                      </Text>
                      <Text fontSize="sm">{username}</Text>
                    </Flex>
                    <Flex>
                      <Text
                        fontSize="sm"
                        fontWeight="semibold"
                        mr="2"
                        color="gray.700"
                      >
                        Hasło:
                      </Text>
                      <Text fontSize="sm">{password}</Text>
                    </Flex>
                  </Flex>
                </Flex>
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Anuluj
              </Button>
              <Button
                colorScheme="green"
                onClick={() => handleSubmit()}
                ml={3}
                isLoading={accessMutation.isLoading}
                isDisabled={username !== "" && true}
              >
                Utwórz
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default AccessRequestDialog;
