import React, { useEffect, useState } from "react";

import { useQueryClient, useMutation } from "react-query";
import { FiTrash2 } from "react-icons/fi";
import { useFormik } from "formik";
import * as Yup from "yup";

import { deleteClientTaxIdentificator } from "../../../api/client/client";

import {
  Text,
  Flex,
  Input,
  Checkbox,
  Box,
  Button,
  Tooltip,
  useToast,
} from "@chakra-ui/react";

const TaxIdentificator = (props) => {
  const { tax } = props;
  const toast = useToast();
  const queryClient = useQueryClient();

  const activityMutation = useMutation(deleteClientTaxIdentificator, {
    onSuccess: () => {
      toast({
        title: "Sukces!",
        position: "top-right",
        description: "Numer został usunięty!",
        status: "success",
        duration: 4000,
        isClosable: true,
      });

      queryClient.invalidateQueries("fetchClientTaxIdentificators");
    },
    onError: () =>
      toast({
        title: "Uwaga, wystapił problem",
        position: "top-right",
        description: "Spróbuj ponownie później!",
        status: "error",
        duration: 4000,
        isClosable: true,
      }),
  });

  const handleActivity = () => {
    activityMutation.mutate(tax.pk);
  };

  return (
    <>
      <Flex direction="row" w="full" my="2">
        <Text>{tax.client_nip}</Text>
        <Button
          ml="auto"
          size="xs"
          colorScheme={"red"}
          variant="ghost"
          onClick={() => handleActivity()}
        >
          Skasuj
        </Button>
      </Flex>
    </>
  );
};

export default TaxIdentificator;
