import React, { useState } from "react";

import {
  Flex,
  Box,
  Text,
  Button,
  Input,
  FormControl,
  FormLabel,
  SimpleGrid,
} from "@chakra-ui/react";

const ClientConfig = (props) => {
  const { clientFormik } = props;
  return (
    <Flex justify="center" p="35px">
      <SimpleGrid w="70%" spacingX="30px" spacingY="15px" columns="2">
        <Flex direction="column" mb="2">
          <FormControl id="available_checklists" isRequired>
            <FormLabel fontWeight="semibold" fontSize="sm">
              Dostępne checklisty
            </FormLabel>
            <Input
              type="text"
              name="available_checklists"
              onChange={clientFormik.handleChange}
              value={clientFormik.values.available_checklists}
              placeholder="Wprowadź liczbę dostępnych checklist"
            />

            {clientFormik.errors.available_checklists &&
              clientFormik.touched.available_checklists && (
                <Text mt="5px" color="red.500">
                  {clientFormik.errors.available_checklists}
                </Text>
              )}
          </FormControl>
        </Flex>
        <Flex direction="column" mb="2">
          <FormControl id="available_users" isRequired>
            <FormLabel fontWeight="semibold" fontSize="sm">
              Dostępni użytkownicy
            </FormLabel>
            <Input
              type="text"
              name="available_users"
              onChange={clientFormik.handleChange}
              value={clientFormik.values.available_users}
              placeholder="Wprowadź liczbę dostępnych użytkowników"
            />

            {clientFormik.errors.available_users &&
              clientFormik.touched.available_users && (
                <Text mt="5px" color="red.500">
                  {clientFormik.errors.available_users}
                </Text>
              )}
          </FormControl>
        </Flex>
        <Flex direction="column" mb="2">
          <FormControl id="available_report_emails" isRequired>
            <FormLabel fontWeight="semibold" fontSize="sm">
              Dostępne sloty email
            </FormLabel>
            <Input
              type="text"
              name="available_report_emails"
              onChange={clientFormik.handleChange}
              value={clientFormik.values.available_report_emails}
              placeholder="Wprowadź liczbę dostępnych slotów email"
            />

            {clientFormik.errors.available_report_emails &&
              clientFormik.touched.available_report_emails && (
                <Text mt="5px" color="red.500">
                  {clientFormik.errors.available_report_emails}
                </Text>
              )}
          </FormControl>
        </Flex>
        <Flex direction="column" mb="2">
          <FormControl id="available_units" isRequired>
            <FormLabel fontWeight="semibold" fontSize="sm">
              Dostępne jednostki
            </FormLabel>
            <Input
              type="text"
              name="available_units"
              onChange={clientFormik.handleChange}
              value={clientFormik.values.available_units}
              placeholder="Wprowadź liczbę dostępnych jednostek"
            />

            {clientFormik.errors.available_units &&
              clientFormik.touched.available_units && (
                <Text mt="5px" color="red.500">
                  {clientFormik.errors.available_units}
                </Text>
              )}
          </FormControl>
        </Flex>
        <Flex direction="column" mb="2">
          <FormControl id="available_photo_quaility" isRequired>
            <FormLabel fontWeight="semibold" fontSize="sm">
              Dostępna jakość zdjęć
            </FormLabel>
            <Input
              type="text"
              name="available_photo_quaility"
              onChange={clientFormik.handleChange}
              value={clientFormik.values.available_photo_quaility}
              placeholder="Wprowadź jakość zdjęć"
            />

            {clientFormik.errors.available_photo_quaility &&
              clientFormik.touched.available_photo_quaility && (
                <Text mt="5px" color="red.500">
                  {clientFormik.errors.available_photo_quaility}
                </Text>
              )}
          </FormControl>
        </Flex>
      </SimpleGrid>
    </Flex>
  );
};

export default ClientConfig;
