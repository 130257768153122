import React from "react";
import moment from "moment";
import NumberFormat from "react-number-format";

import { useTable, useSortBy } from "react-table";

import {
  Flex,
  Button,
  Box,
  Text,
  Checkbox,
  Input,
  Select,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { FiSearch, FiChevronUp, FiChevronDown } from "react-icons/fi";
import "moment/locale/pl";

const ActivityTable = (props) => {
  const { activities } = props;

  const table = [];
  if (activities) {
    activities.map((d) => {
      table.push({
        created_at: d.created_at,
        count: d.count,
      });
    });
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "Dzień",
        accessor: "created_at",
      },
      {
        Header: "Liczba zrealizowanych zadań",
        accessor: "count",
      },
    ],
    []
  );

  const tableData = React.useMemo(() => table, [activities]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,

    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: tableData,
      initialState: {
        sortBy: [
          {
            id: "created_at",
            desc: true,
          },
        ],
      },
    },
    useSortBy
  );
  return (
    <Flex w="full" direction="column">
      <table {...getTableProps()} style={{ width: "100%" }}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  onClick={() => column.toggleSortBy(!column.isSortedDesc)}
                  key={column.render("Header")}
                  style={{
                    borderBottom: "solid 1px #cecece",
                    color: "#7f7f7f",
                    fontWeight: "bold",
                    paddingBottom: "15px",
                    fontSize: "13px",
                    cursor: "pointer",
                  }}
                >
                  <Flex justify="flex-start">
                    <Text mr="2">{column.render("Header")}</Text>
                    <Text fontSize="15px">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <Box textColor="#2484ea" as={FiChevronDown}></Box>
                        ) : (
                          <Box textColor="#2484ea" as={FiChevronUp}></Box>
                        )
                      ) : (
                        ""
                      )}
                    </Text>
                  </Flex>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        padding: "10px",
                        borderBottom: "solid 1px #dedede",
                        fontSize: "14px",
                      }}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Flex>
  );
};

export default ActivityTable;
