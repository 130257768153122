import React, { useState } from "react";

import { FiCodesandbox, FiUser, FiSearch } from "react-icons/fi";

import { Text, Flex, Box, Spinner, Button } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";

import HorizontalNav from "../components/navs/HorizontalNav";
import CreateCategoryModal from "../components/modals/community/CreateCategoryModal";

import { fetchCategories } from "../api/community/question";

const Community = () => {
  const [screenType, setScreenType] = useState(1);

  const {
    isLoading: questionCategoryIsLoading,
    data: questionCategoryData,
    isSuccess: questionCategoryIsSuccess,
  } = useQuery("fetchCategories", fetchCategories, {
    retry: false,
    refetchOnWindowFocus: false,
  });

  const handleScreenSwitch = (type) => {
    setScreenType(type);
  };

  const menu = [
    {
      title: "Baza pytań",
      icon: FiCodesandbox,
      action: handleScreenSwitch,
      type: 1,
    },
    {
      title: "Społeczność",
      icon: FiUser,
      action: handleScreenSwitch,
      type: 2,
    },
  ];

  return (
    <Flex direction="column" w="full">
      <Flex mb="5">
        <Box>
          <Text fontSize="2xl" color="primary.black" fontWeight="regular">
            <Text as="span" fontWeight="semibold">
              Zarządzanie społecznością
            </Text>
          </Text>
        </Box>
      </Flex>

      <Flex>
        <Flex w={["100%", "100%", "100%", "20%"]}>
          <HorizontalNav elements={menu} />
        </Flex>
        <Flex px="5" w={["100%", "100%", "100%", "80%"]}>
          {screenType == 1 && (
            <Flex
              bg="white"
              w="100%"
              p="5"
              shadow="sm"
              borderRadius="md"
              d="column"
            >
              <Flex
                align="center"
                borderBottom="1px solid"
                borderColor="gray.300"
                pb="4"
              >
                <Text fontWeight="semibold" fontSize="lg" mr="1">
                  Baza pytań{" "}
                </Text>
                {questionCategoryIsSuccess && (
                  <Text fontWeight="semibold" fontSize="lg">
                    ({questionCategoryData.length})
                  </Text>
                )}

                <Flex ml="auto">
                  <CreateCategoryModal />
                </Flex>
              </Flex>

              {questionCategoryIsLoading ? (
                <Flex w="full" py="5" justify="center">
                  <Spinner />
                </Flex>
              ) : (
                questionCategoryIsSuccess && (
                  <>
                    <Flex
                      py="2"
                      borderBottom="1px solid"
                      borderColor="gray.200"
                    >
                      <Flex w="30%">
                        <Text
                          fontSize="sm"
                          color="gray.600"
                          fontWeight="semibold"
                        >
                          Nazwa
                        </Text>
                      </Flex>
                      <Flex w="60%">
                        <Text
                          fontSize="sm"
                          color="gray.600"
                          fontWeight="semibold"
                        >
                          Opis
                        </Text>
                      </Flex>
                      <Flex w="10%"></Flex>
                    </Flex>
                    {questionCategoryData.map((category) => (
                      <Flex
                        py="1"
                        borderBottom="1px solid"
                        borderColor="gray.200"
                        align="center"
                        key={category.pk}
                      >
                        <Flex w="30%">
                          <Text fontSize="sm">{category.name}</Text>
                        </Flex>
                        <Flex w="60%">
                          <Text fontSize="sm">{category.description}</Text>
                        </Flex>
                        <Flex w="10%">
                          <Link to={`/community/baza/${category.pk}`}>
                            <Button>
                              <Box as={FiSearch} />
                            </Button>
                          </Link>
                        </Flex>
                      </Flex>
                    ))}
                  </>
                )
              )}
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Community;
