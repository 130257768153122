import React, { useState } from "react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  useToast,
  SimpleGrid,
  Box,
  Divider,
} from "@chakra-ui/react";

import { useFormik } from "formik";
import { useMutation, useQueryClient } from "react-query";
import * as Yup from "yup";
import Select from "react-select";

import {
  updateClient,
  updateClientAdditional,
} from "../../../api/client/client";

const EditClientModal = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { client } = props;

  const toast = useToast();
  const queryClient = useQueryClient();

  const priorityOptions = [
    {
      label: "Niski",
      value: "1",
    },
    {
      label: "Średni",
      value: "2",
    },
    {
      label: "Wysoki",
      value: "3",
    },
  ];

  const statusOptions = [
    {
      label: "Odmowa",
      value: "1",
    },
    {
      label: "Aktualny klient",
      value: "2",
    },
    {
      label: "Do potencjalnego kontaktu",
      value: "3",
    },
    {
      label: "Były klient",
      value: "4",
    },
    {
      label: "Demo",
      value: "5",
    },
    {
      label: "Po prezentacji",
      value: "6",
    },
    {
      label: "W trakcie rozmów",
      value: "0",
    },
  ];

  const updateClientMutation = useMutation(updateClient, {
    onSuccess: () => {
      toast({
        title: "Sukces!",
        position: "top-right",
        description: "Klient został poprawnie zaktualizowany!",
        status: "success",
        duration: 4000,
        isClosable: true,
      });

      queryClient.invalidateQueries("fetchClient");
      queryClient.invalidateQueries("fetchClientChanges");
      onClose();
    },
    onError: () =>
      toast({
        title: "Uwaga, wystapił problem",
        position: "top-right",
        description: "Spróbuj ponownie później!",
        status: "error",
        duration: 4000,
        isClosable: true,
      }),
  });

  const updateClientAdditionalMutation = useMutation(updateClientAdditional, {
    onSuccess: () => {
      toast({
        title: "Sukces!",
        position: "top-right",
        description: "Klient został poprawnie zaktualizowany!",
        status: "success",
        duration: 4000,
        isClosable: true,
      });

      queryClient.invalidateQueries("fetchClient");
      queryClient.invalidateQueries("fetchClientChanges");
      onClose();
    },
    onError: () =>
      toast({
        title: "Uwaga, wystapił problem",
        position: "top-right",
        description: "Spróbuj ponownie później!",
        status: "error",
        duration: 4000,
        isClosable: true,
      }),
  });

  const ClientSchema = Yup.object().shape({
    name: Yup.string()
      .nullable()
      .required("To pole jest wymagane!")
      .max(100, "Przekroczono liczbę znaków!")
      .min(2, "Za mała liczba znaków!"),
    company_name: Yup.string()
      .nullable()
      .required("To pole jest wymagane!")
      .max(200, "Przekroczono liczbę znaków!")
      .min(2, "Za mała liczba znaków!"),
    contact_name: Yup.string()
      .nullable()
      .required("To pole jest wymagane!")
      .max(100, "Przekroczono liczbę znaków!")
      .min(5, "Za mała liczba znaków!"),
    contact_email: Yup.string()
      .nullable()
      .nullable()
      .email("Nieprawidłowy format!")
      .max(200, "Przekroczono liczbę znaków!")
      .min(2, "Za mała liczba znaków!"),
    contact_phone: Yup.string()
      .nullable()
      .required("To pole jest wymagane!")
      .max(200, "Przekroczono liczbę znaków!")
      .min(2, "Za mała liczba znaków!"),
    client_nip: Yup.string()
      .nullable()
      .required("To pole jest wymagane!")
      .max(12, "Przekroczono liczbę znaków!")
      .min(10, "Za mała liczba znaków!"),
    priority: Yup.number()
      .nullable()
      .required("To pole jest wymagane!")
      .typeError("To pole musi być liczbą!"),
    status: Yup.number()
      .nullable()
      .required("To pole jest wymagane!")
      .typeError("To pole musi być liczbą!"),
    invoice_date: Yup.number()
      .nullable()
      .required("To pole jest wymagane!")
      .typeError("To pole musi być liczbą!"),
    price_per_unit: Yup.number()
      .nullable()
      .required("To pole jest wymagane!")
      .typeError("To pole musi być liczbą!"),
  });

  const clientFormik = useFormik({
    initialValues: {
      id: client.id,
      name: client.name,
      company_name: client.company_name,
      contact_name: client.additional_details.contact_name,
      contact_phone: client.additional_details.contact_phone,
      contact_email: client.additional_details.contact_email,
      client_nip: client.additional_details.client_nip,
      invoice_date: client.additional_details.invoice_date,
      price_per_unit: client.additional_details.price_per_unit,
      priority: client.additional_details.priority,
      status: client.additional_details.status,
    },
    validationSchema: ClientSchema,
    onSubmit: (values) => {
      const client_payload = {
        id: values.id,
        name: values.name,
        company_name: values.company_name,
      };

      const additional_payload = {
        id: values.id,
        contact_name: values.contact_name,
        contact_phone: values.contact_phone,
        contact_email: values.contact_email,
        client_nip: values.client_nip,
        invoice_date: values.invoice_date,
        price_per_unit: values.price_per_unit,
        priority: values.priority,
        status: values.status,
      };

      updateClientAdditionalMutation.mutate(additional_payload);
      updateClientMutation.mutate(client_payload);
    },
  });

  const handleSubmit = () => {
    clientFormik.submitForm();
  };

  const handlePriorityField = (value) => {
    if (value) {
      clientFormik.setFieldValue("priority", value.value);
    }
  };

  const handleStatusField = (value) => {
    if (value) {
      clientFormik.setFieldValue("status", value.value);
    }
  };

  return (
    <>
      <Button
        size="xs"
        ml="auto"
        colorScheme="blue"
        variant="ghost"
        onClick={onOpen}
      >
        Edytuj
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edytuj klienta</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={clientFormik.handleSubmit}>
              <Flex direction="column" mb="2">
                <FormControl id="year" isRequired>
                  <FormLabel>Nazwa klienta</FormLabel>
                  <Input
                    type="text"
                    name="name"
                    onChange={clientFormik.handleChange}
                    value={clientFormik.values.name}
                    placeholder="Wprowadź nazwę"
                  />

                  {clientFormik.errors.name && clientFormik.touched.name && (
                    <Text mt="5px" color="red.500">
                      {clientFormik.errors.name}
                    </Text>
                  )}
                </FormControl>
              </Flex>
              <Flex direction="column" mb="2">
                <FormControl id="year" isRequired>
                  <FormLabel>Nazwa firmy</FormLabel>
                  <Input
                    type="text"
                    name="company_name"
                    onChange={clientFormik.handleChange}
                    value={clientFormik.values.company_name}
                    placeholder="Wprowadź nazwę"
                  />

                  {clientFormik.errors.company_name &&
                    clientFormik.touched.company_name && (
                      <Text mt="5px" color="red.500">
                        {clientFormik.errors.company_name}
                      </Text>
                    )}
                </FormControl>
              </Flex>
              <Flex direction="column" mb="2">
                <FormControl id="year" isRequired>
                  <FormLabel>Osoba kontaktowa</FormLabel>
                  <Input
                    type="text"
                    name="contact_name"
                    onChange={clientFormik.handleChange}
                    value={clientFormik.values.contact_name}
                    placeholder="Wprowadź nazwę"
                  />

                  {clientFormik.errors.contact_name &&
                    clientFormik.touched.contact_name && (
                      <Text mt="5px" color="red.500">
                        {clientFormik.errors.contact_name}
                      </Text>
                    )}
                </FormControl>
              </Flex>
              <Flex direction="column" mb="2">
                <FormControl id="year">
                  <FormLabel>Adres e-mail</FormLabel>
                  <Input
                    type="email"
                    name="contact_email"
                    onChange={clientFormik.handleChange}
                    value={clientFormik.values.contact_email}
                    placeholder="Wprowadź adres"
                  />

                  {clientFormik.errors.contact_email &&
                    clientFormik.touched.contact_email && (
                      <Text mt="5px" color="red.500">
                        {clientFormik.errors.contact_email}
                      </Text>
                    )}
                </FormControl>
              </Flex>
              <SimpleGrid columns="2" spacing="4">
                <Flex direction="column" mb="2">
                  <FormControl id="year" isRequired>
                    <FormLabel>Numer telefonu</FormLabel>
                    <Input
                      type="text"
                      name="contact_phone"
                      onChange={clientFormik.handleChange}
                      value={clientFormik.values.contact_phone}
                      placeholder="Wprowadź nazwę"
                    />

                    {clientFormik.errors.contact_phone &&
                      clientFormik.touched.contact_phone && (
                        <Text mt="5px" color="red.500">
                          {clientFormik.errors.contact_phone}
                        </Text>
                      )}
                  </FormControl>
                </Flex>
                <Flex direction="column" mb="2">
                  <FormControl id="year" isRequired>
                    <FormLabel>NIP</FormLabel>
                    <Input
                      type="text"
                      name="client_nip"
                      onChange={clientFormik.handleChange}
                      value={clientFormik.values.client_nip}
                      placeholder="Wprowadź nazwę"
                    />

                    {clientFormik.errors.client_nip &&
                      clientFormik.touched.client_nip && (
                        <Text mt="5px" color="red.500">
                          {clientFormik.errors.client_nip}
                        </Text>
                      )}
                  </FormControl>
                </Flex>
              </SimpleGrid>

              <Flex direction="column" mb="2">
                <FormControl id="priority" isRequired>
                  <FormLabel>Priorytet</FormLabel>
                  <Box
                    as={Select}
                    cacheOptions
                    placeholder="Wybierz priorytet"
                    isClearable={true}
                    onChange={handlePriorityField}
                    classNamePrefix="react-select"
                    loadingMessage={() => "Wyszukiwanie.."}
                    noOptionsMessage={() => "Nie znaleziono!"}
                    options={priorityOptions}
                    defaultValue={priorityOptions.filter(
                      (option) => option.value == clientFormik.values.priority
                    )}
                    classNamePrefix={
                      clientFormik.errors.priority
                        ? "react-select-invalid"
                        : "react-select"
                    }
                  />

                  {clientFormik.errors.priority && (
                    <Text mt="5px" color="red.500">
                      {clientFormik.errors.priority}
                    </Text>
                  )}
                </FormControl>
              </Flex>
              <Flex direction="column" mb="2">
                <FormControl id="priority" isRequired>
                  <FormLabel>Status</FormLabel>
                  <Box
                    as={Select}
                    cacheOptions
                    placeholder="Wybierz status"
                    isClearable={true}
                    onChange={handleStatusField}
                    classNamePrefix="react-select"
                    loadingMessage={() => "Wyszukiwanie.."}
                    noOptionsMessage={() => "Nie znaleziono!"}
                    options={statusOptions}
                    defaultValue={statusOptions.filter(
                      (option) => option.value == clientFormik.values.status
                    )}
                    classNamePrefix={
                      clientFormik.errors.status
                        ? "react-select-invalid"
                        : "react-select"
                    }
                  />

                  {clientFormik.errors.status && (
                    <Text mt="5px" color="red.500">
                      {clientFormik.errors.status}
                    </Text>
                  )}
                </FormControl>
              </Flex>

              <Divider
                w="full"
                h="0.5px"
                orientation="horizontal"
                bg="gray.400"
                my="25px"
              />

              <Flex direction="column" mb="2">
                <FormControl id="year" isRequired>
                  <FormLabel>Dzień miesiąca fakturowania</FormLabel>
                  <Input
                    type="text"
                    name="invoice_date"
                    onChange={clientFormik.handleChange}
                    value={clientFormik.values.invoice_date}
                    placeholder="Wprowadź nazwę"
                  />

                  {clientFormik.errors.invoice_date &&
                    clientFormik.touched.invoice_date && (
                      <Text mt="5px" color="red.500">
                        {clientFormik.errors.invoice_date}
                      </Text>
                    )}
                </FormControl>
              </Flex>
              <Flex direction="column" mb="2">
                <FormControl id="year" isRequired>
                  <FormLabel>Cena netto PLN za jednostkę</FormLabel>
                  <Input
                    type="text"
                    name="price_per_unit"
                    onChange={clientFormik.handleChange}
                    value={clientFormik.values.price_per_unit}
                    placeholder="Wprowadź nazwę"
                  />

                  {clientFormik.errors.price_per_unit &&
                    clientFormik.touched.price_per_unit && (
                      <Text mt="5px" color="red.500">
                        {clientFormik.errors.price_per_unit}
                      </Text>
                    )}
                </FormControl>
              </Flex>
            </form>
          </ModalBody>

          <ModalFooter>
            <Button size="sm" variant="ghost" mr={3} onClick={onClose}>
              Zamknij
            </Button>
            <Button size="sm" onClick={handleSubmit} colorScheme="green">
              Zapisz zmiany
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditClientModal;
