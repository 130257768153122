import React, { useEffect, useState } from "react";

import { useQueryClient, useMutation } from "react-query";
import { FiTrash2 } from "react-icons/fi";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  storeQuestion,
  updateQuestion,
  deleteQuestion,
} from "../../../api/community/question";

import {
  Text,
  Flex,
  Input,
  Checkbox,
  Box,
  Button,
  Tooltip,
  useToast,
} from "@chakra-ui/react";

const QuestionCreate = (props) => {
  const { category, question, setNewQuestion, newQuestion } = props;
  const toast = useToast();
  const queryClient = useQueryClient();

  const [expandMore, setExpandMore] = useState(true);

  const storeQuestionMutation = useMutation(storeQuestion, {
    onSuccess: () => {
      toast({
        title: "Sukces!",
        position: "top-right",
        description: "Pytanie zostało utworzone!",
        status: "success",
        duration: 4000,
        isClosable: true,
      });

      queryClient.invalidateQueries("fetchCategory");
      setNewQuestion(newQuestion - 1);
    },
    onError: () =>
      toast({
        title: "Uwaga, wystapił problem",
        position: "top-right",
        description: "Spróbuj ponownie później!",
        status: "error",
        duration: 4000,
        isClosable: true,
      }),
  });

  const deleteQuestionMutation = useMutation(deleteQuestion, {
    onSuccess: () => {
      toast({
        title: "Sukces!",
        position: "top-right",
        description: "Pytanie zostało skasowane!",
        status: "success",
        duration: 4000,
        isClosable: true,
      });

      queryClient.invalidateQueries("fetchCategory");
    },
    onError: () =>
      toast({
        title: "Uwaga, wystapił problem",
        position: "top-right",
        description: "Spróbuj ponownie później!",
        status: "error",
        duration: 4000,
        isClosable: true,
      }),
  });

  const updateQuestionMutation = useMutation(updateQuestion, {
    onSuccess: () => {
      toast({
        title: "Sukces!",
        position: "top-right",
        description: "Pytanie zostało zaktualizowane!",
        status: "success",
        duration: 4000,
        isClosable: true,
      });

      queryClient.invalidateQueries("fetchCategory");
    },
    onError: () =>
      toast({
        title: "Uwaga, wystapił problem",
        position: "top-right",
        description: "Spróbuj ponownie później!",
        status: "error",
        duration: 4000,
        isClosable: true,
      }),
  });

  const QuestionSchema = Yup.object().shape({
    question: Yup.string()
      .required("To pole jest wymagane!")
      .max(255, "To pole jest za długie!"),
  });

  const questionFormik = useFormik({
    initialValues: {
      pk: question ? question.pk : "",
      category: category.pk,
      question: question ? question.question : "",
      is_logical: question ? question.is_logical : false,
      is_text: question ? question.is_text : false,
      is_photo: question ? question.is_photo : false,
      is_quickmail: question ? question.is_quickmail : false,
      is_time_question: question ? question.is_time_question : false,
    },
    validationSchema: QuestionSchema,
    onSubmit: (values) => {
      if (question) {
        updateQuestionMutation.mutate(values);
      } else {
        storeQuestionMutation.mutate(values);
      }
    },
  });

  const handleDelete = () => {
    if (question) {
      deleteQuestionMutation.mutate(question.pk);
    } else {
      setNewQuestion(newQuestion - 1);
    }
  };

  useEffect(() => {
    if (questionFormik.values.is_time_question !== false) {
      setExpandMore(true);
    } else {
      setExpandMore(false);
    }
  }, [questionFormik.values.is_time_question]);
  return (
    <>
      <Flex direction="row" w="full" my="2">
        <Flex w="40%" d="column">
          <Input
            size="sm"
            value={questionFormik.values.question}
            onChange={questionFormik.handleChange}
            name="question"
            placeholder="Pytanie"
          />

          {questionFormik.errors.question && questionFormik.touched.question && (
            <Text mt="5px" color="red.500">
              {questionFormik.errors.question}
            </Text>
          )}
        </Flex>
        <Flex w="10%" px="5" justify="center">
          <Checkbox
            size="lg"
            isChecked={questionFormik.values.is_logical}
            onChange={questionFormik.handleChange}
            name="is_logical"
            colorScheme="green"
          />
        </Flex>
        <Flex w="10%" px="5" justify="center">
          <Checkbox
            size="lg"
            isChecked={questionFormik.values.is_text}
            onChange={questionFormik.handleChange}
            name="is_text"
            colorScheme="green"
          />
        </Flex>
        <Flex w="10%" px="5" justify="center">
          <Checkbox
            size="lg"
            isChecked={questionFormik.values.is_photo}
            onChange={questionFormik.handleChange}
            name="is_photo"
            colorScheme="green"
          />
        </Flex>
        <Flex w="10%" px="5" justify="center">
          <Checkbox
            size="lg"
            isChecked={questionFormik.values.is_quickmail}
            onChange={questionFormik.handleChange}
            name="is_quickmail"
            colorScheme="green"
          />
        </Flex>
        <Flex w="10%" px="5" justify="center">
          <Checkbox
            size="lg"
            isChecked={questionFormik.values.is_time_question}
            onChange={questionFormik.handleChange}
            name="is_time_question"
            colorScheme="green"
          />
        </Flex>
        <Flex w="10%" px="5" justify="end">
          <Button
            size="sm"
            colorScheme="green"
            onClick={() => questionFormik.submitForm()}
            isLoading={
              question
                ? updateQuestionMutation.isLoading
                : storeQuestionMutation.isLoading
            }
          >
            Zapisz
          </Button>
          <Button
            ml="1"
            size="sm"
            colorScheme="red"
            onClick={() => handleDelete()}
            isLoading={deleteQuestionMutation.isLoading}
          >
            <Box as={FiTrash2} />
          </Button>
        </Flex>
      </Flex>

      {expandMore ? (
        <Flex p="5" border="1px solid" borderColor="gray.200">
          <Text fontWeight="semibold" fontSize="sm">
            Funkcje zaawansowane
          </Text>
        </Flex>
      ) : (
        <Box w="100%" h="1px" bg="gray.300" />
      )}
    </>
  );
};

export default QuestionCreate;
