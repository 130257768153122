import React from "react";
import NumberFormat from "react-number-format";

import InvoicesTable from "../../components/tables/invoices/InvoicesTable";

import { useQuery } from "react-query";

import {
  Text,
  Flex,
  Box,
  Button,
  Link,
  Spinner,
  SimpleGrid,
} from "@chakra-ui/react";

import { fetchInvoices } from "../../api/client/client";

const InvoicesList = (props) => {
  const {
    isLoading: invoicesIsLoading,
    data: invoicesData,
    isSuccess: invoicesIsSuccess,
  } = useQuery("fetchInvoices", fetchInvoices);

  return (
    <Flex direction="column" w="full">
      <Flex>
        <Box>
          <Text fontSize="2xl" color="primary.black" fontWeight="regular">
            <Text as="span" fontWeight="semibold">
              Lista faktur
            </Text>
          </Text>
        </Box>

        <Box ml="auto">
          <Link href="/">
            <Button variant="ghost" size="sm">
              Wróć
            </Button>
          </Link>
        </Box>
      </Flex>
      <Flex d="column" mt="6" w="full">
        {invoicesIsLoading ? (
          <Flex>
            <Spinner />
          </Flex>
        ) : (
          invoicesIsSuccess &&
          invoicesData && (
            <Flex d="column">
              <SimpleGrid columns="5" mb="4">
                <Flex d="column">
                  <Text>Suma brutto</Text>
                  <Text>
                    <NumberFormat
                      value={invoicesData.total.gross_sum}
                      thousandSeparator={" "}
                      decimalScale="2"
                      decimalSeparator=","
                      displayType={"text"}
                      isNumericString="true"
                    />
                  </Text>
                </Flex>
                <Flex d="column">
                  <Text>Suma netto</Text>
                  <Text>
                    <NumberFormat
                      value={invoicesData.total.net_sum}
                      thousandSeparator={" "}
                      decimalScale="2"
                      decimalSeparator=","
                      displayType={"text"}
                      isNumericString="true"
                    />
                  </Text>
                </Flex>
                <Flex d="column">
                  <Text>Suma zapłat</Text>
                  <Text>
                    <NumberFormat
                      value={invoicesData.total.paid_sum}
                      thousandSeparator={" "}
                      decimalScale="2"
                      decimalSeparator=","
                      displayType={"text"}
                      isNumericString="true"
                    />
                  </Text>
                </Flex>
                <Flex d="column">
                  <Text>Do zapłaty</Text>
                  <Text>
                    <NumberFormat
                      value={
                        invoicesData.total.gross_sum -
                        invoicesData.total.paid_sum
                      }
                      thousandSeparator={" "}
                      decimalScale="2"
                      decimalSeparator=","
                      displayType={"text"}
                      isNumericString="true"
                    />
                  </Text>
                </Flex>
                <Flex d="column">
                  <Text>Ściągalność</Text>
                  <Text>
                    <NumberFormat
                      value={
                        (invoicesData.total.paid_sum /
                          invoicesData.total.gross_sum) *
                        100
                      }
                      thousandSeparator={" "}
                      decimalScale="2"
                      decimalSeparator=","
                      displayType={"text"}
                      isNumericString="true"
                    />
                    %
                  </Text>
                </Flex>
              </SimpleGrid>
              <Flex d="column">
                <Text fontSize="lg" fontWeight="semibold" mb="5">
                  Niezapłacone faktury
                </Text>
                <Flex d="column" bg="white" p="5" borderRadius="lg">
                  <InvoicesTable invoices={invoicesData.unpaid_objects} />
                </Flex>
              </Flex>

              <Flex d="column" mt="5">
                <Text fontSize="lg" fontWeight="semibold" mb="5">
                  Wszystkie faktury
                </Text>
                <Flex d="column" bg="white" p="5" borderRadius="lg">
                  <InvoicesTable invoices={invoicesData.objects} />
                </Flex>
              </Flex>
            </Flex>
          )
        )}
      </Flex>
    </Flex>
  );
};

export default InvoicesList;
