import React from "react";

import { Box, Flex, Link } from "@chakra-ui/react";
import { withRouter } from "react-router-dom";

const SidebarLink = (props) => {
  return (
    <Link href={props.href}>
      <Flex
        cursor="pointer"
        direction="row"
        w="fit-content"
        h="fit-content"
        justify="center"
        bg={
          (props.href === "/" && props.href === props.location.pathname) ||
          (props.href !== "/" &&
            props.location.pathname.indexOf(props.href) > -1)
            ? "primary.green"
            : "gray.200"
        }
        p="15px"
        borderRadius="lg"
        my="18px"
      >
        <Box
          as={props.icon}
          fontSize="2xl"
          color={
            (props.href === "/" && props.href === props.location.pathname) ||
            (props.href !== "/" &&
              props.location.pathname.indexOf(props.href) > -1)
              ? "white"
              : "gray.700"
          }
        ></Box>
      </Flex>
    </Link>
  );
};

export default withRouter(SidebarLink);
