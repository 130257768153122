import React from "react";

import { Flex, Text, Box } from "@chakra-ui/react";

const HorizontalNavElement = (props) => {
  const { title, icon, action, type } = props;

  return (
    <Flex
      w="full"
      py="4"
      px="3"
      borderBottom="1px solid"
      borderColor="gray.200"
      onClick={() => action(type)}
      cursor="pointer"
    >
      <Box as={icon} w="22px" h="22px" color="gray.500" mr="5" />
      <Text fontSize="md" fontWeight="semibold" color="gray.700">
        {title}
      </Text>
    </Flex>
  );
};

export default HorizontalNavElement;
