import React from "react";

import ClientTable from "../components/tables/ClientTable";

import { useQuery } from "react-query";

import { Text, Flex, Box, Button, Link, Spinner } from "@chakra-ui/react";

import { fetchClients } from "../api/client/client";

const Home = () => {
  const { isLoading, isSuccess, data } = useQuery("fetchClients", fetchClients);

  return (
    <Flex direction="column" w="full">
      <Flex>
        <Box>
          <Text fontSize="2xl" color="primary.black" fontWeight="regular">
            <Text fontWeight="semibold">
              Lista klientów {isSuccess && data && <>({data.length})</>}
            </Text>
            <Text fontSize="sm" color="gray.600">
              {isSuccess && data && (
                <>
                  Aktywni: (
                  {data.filter((client) => client.is_active === true).length})
                  Nieaktywni: (
                  {data.filter((client) => client.is_active === false).length})
                </>
              )}
            </Text>
          </Text>
        </Box>

        <Box ml="auto">
          <Link href="/klient/utworz">
            <Button bg="primary.green" size="sm" color="white">
              Nowy klient
            </Button>
          </Link>
        </Box>
      </Flex>

      {isLoading ? (
        <Flex py="5" justify="center">
          <Spinner />
        </Flex>
      ) : (
        isSuccess &&
        data && (
          <Flex
            bg="white"
            p="4"
            shadow="md"
            borderRadius="lg"
            mt="6"
            overflowX="auto"
          >
            <ClientTable clients={data} />
          </Flex>
        )
      )}
    </Flex>
  );
};

export default Home;
