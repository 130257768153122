import React from "react";

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  IconButton,
  Box,
  Button,
  useToast,
  MenuItem,
} from "@chakra-ui/react";

import { FiTrash2 } from "react-icons/fi";

import { removeClientDocument } from "../../api/client/client";
import { useMutation, useQueryClient } from "react-query";

const DeleteDocumentDialog = (props) => {
  const { id } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = React.useRef();
  const toast = useToast();
  const queryClient = useQueryClient();

  const removeDocumentMutation = useMutation(removeClientDocument, {
    onSuccess: () => {
      toast({
        title: "Sukces!",
        position: "top-right",
        description: "Dokument został skasowany!",
        status: "success",
        duration: 4000,
        isClosable: true,
      });

      onClose();
      queryClient.invalidateQueries("fetchClientDocuments");
    },
    onError: () =>
      toast({
        title: "Uwaga, wystapił problem",
        position: "top-right",
        description: "Spróbuj ponownie później!",
        status: "error",
        duration: 4000,
        isClosable: true,
      }),
  });

  const handleDelete = () => {
    removeDocumentMutation.mutate(id);
    onClose();
  };

  return (
    <>
      <MenuItem color="red.500" onClick={() => setIsOpen(true)}>
        Usuń
      </MenuItem>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Usuń dokument
            </AlertDialogHeader>

            <AlertDialogBody>
              Czy chcesz to zrobić na pewno? Nie będzie możliwości cofnięcia tej
              akcji.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Anuluj
              </Button>
              <Button
                colorScheme="red"
                onClick={() => handleDelete()}
                ml={3}
                isLoading={removeDocumentMutation.isLoading}
              >
                Skasuj
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default DeleteDocumentDialog;
