import React from "react";

import { Flex } from "@chakra-ui/react";

import HorizontalNavElement from "./HorizontalNavElement";

const HorizontalNav = (props) => {
  const { elements } = props;
  return (
    <Flex w="100%">
      <Flex bg="white" w="100%" p="4" shadow="sm" borderRadius="md" d="column">
        {elements.map((element) => (
          <HorizontalNavElement
            title={element.title}
            icon={element.icon}
            action={element.action && element.action}
            type={element.type && element.type}
            key={element.title}
          />
        ))}
      </Flex>
    </Flex>
  );
};

export default HorizontalNav;
