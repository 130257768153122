import React from "react";
import { useToast } from "@chakra-ui/react";
import { Route, Redirect } from "react-router-dom";

function PrivateRoute({ component: Component, authed, permission, ...rest }) {
  const toast = useToast();

  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("token") ? (
          <Component {...props} />
        ) : (
          <>
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          </>
        )
      }
    />
  );
}

export default PrivateRoute;
