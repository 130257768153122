import React, { useState, useEffect } from "react";
import moment from "moment";
import NumberFormat from "react-number-format";

import EditConfigModal from "../../components/modals/client/EditConfigModal";
import EditClientModal from "../../components/modals/client/EditClientModal";
import DisactiveClientDialog from "../../components/dialogs/client/DisactiveClientDialog";
import AccessRequestDialog from "../../components/dialogs/client/AccessRequestDialog";
import InvoiceReminderModal from "../../components/modals/client/InvoiceReminderModal";
import CreateDocumentModal from "../../components/modals/client/CreateDocumentModal";
import FileThumbnail from "../../components/FileThumbnail";
import ActivityTable from "../../components/tables/client/ActivityTable";
import InvoicesClientTable from "../../components/tables/invoices/InvoicesClientTable";

import { useQuery } from "react-query";

import {
  Text,
  Flex,
  Box,
  Button,
  Link,
  Spinner,
  Divider,
  Stack,
  SimpleGrid,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";

import {
  fetchClient,
  fetchClientInvoices,
  fetchClientDocuments,
  fetchClientChanges,
  fetchClientActivity,
  fetchClientTaxIdentificators,
} from "../../api/client/client";

import CreateTaxIdentificatorModal from "../../components/modals/client/CreateTaxIdentificatorModal";
import TaxIdentificator from "../../components/pagesComponents/client/TaxIdentificator";

const ClientDetail = (props) => {
  const id = props.match.params.id;
  const [clientAdditionalFetched, setClientAdditionalFetched] = useState(false);
  const [clientAdditional, setClientAdditional] = useState();

  const {
    isLoading: clientIsLoading,
    data: clientData,
    isSuccess: clientIsSuccess,
  } = useQuery(["fetchClient", id], fetchClient, {
    retry: false,
    refetchOnWindowFocus: false,
  });

  const {
    isLoading: invoicesIsLoading,
    data: invoicesData,
    isSuccess: invoicesIsSuccess,
  } = useQuery(["fetchClientInvoices", id], fetchClientInvoices, {
    retry: false,
    refetchOnWindowFocus: false,
  });

  const {
    isLoading: documentsIsLoading,
    data: documentsData,
    isSuccess: documentsIsSuccess,
  } = useQuery(["fetchClientDocuments", id], fetchClientDocuments, {
    retry: false,
    refetchOnWindowFocus: false,
  });

  const {
    isLoading: changesIsLoading,
    data: changesData,
    isSuccess: changesIsSuccess,
  } = useQuery(["fetchClientChanges", id], fetchClientChanges, {
    retry: false,
    refetchOnWindowFocus: false,
  });

  const {
    isLoading: activityIsLoading,
    data: activityData,
    isSuccess: activityIsSuccess,
  } = useQuery(["fetchClientActivity", id], fetchClientActivity, {
    retry: false,
    refetchOnWindowFocus: false,
  });

  const {
    isLoading: taxIdentificatorsIsLoading,
    data: taxIdentificatorsData,
    isSuccess: taxIdentificatorsIsSuccess,
  } = useQuery(
    ["fetchClientTaxIdentificators", clientAdditional],
    fetchClientTaxIdentificators,
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: clientAdditionalFetched,
    }
  );

  useEffect(() => {
    if (clientData && clientData.additional_details) {
      setClientAdditionalFetched(true);
      setClientAdditional(clientData.additional_details.pk);
    }
  }, [clientData]);

  return (
    <Flex direction="column" w="full">
      <Flex direction={["column", "column", "column", "row"]}>
        <Box>
          <Text fontSize="2xl" color="primary.black" fontWeight="regular">
            <Text as="span" fontWeight="semibold">
              Szczegóły klienta
            </Text>
          </Text>
        </Box>

        <Box ml={[0, 0, 0, "auto"]} mt={["15px", "15px", "15px", 0]}>
          <Menu>
            <MenuButton
              as={Button}
              size="sm"
              ml="auto"
              colorScheme="blue"
              mr="3"
            >
              Akcja
            </MenuButton>
            <MenuList>
              {clientIsSuccess && (
                <DisactiveClientDialog
                  id={id}
                  is_active={clientData.is_active}
                />
              )}
              {clientIsSuccess && <AccessRequestDialog id={id} />}
              <MenuItem color="red.500">Usuń klienta</MenuItem>
            </MenuList>
          </Menu>
          <Link href="/">
            <Button variant="ghost" size="sm">
              Wróć
            </Button>
          </Link>
        </Box>
      </Flex>
      <Flex d="column" mt="6" w="full">
        {clientIsLoading ? (
          <Flex w="full" py="5" justify="center">
            <Spinner />
          </Flex>
        ) : (
          clientIsSuccess && (
            <>
              <Divider
                w="full"
                h="1px"
                orientation="horizontal"
                bg="gray.400"
              />
              <Flex py="5" direction={["column", "column", "column", "row"]}>
                <Flex
                  w={["100%", "100%", "100%", "30%"]}
                  px="5"
                  direction="column"
                >
                  <Flex direction="column">
                    <Text
                      lineHeight="1.2"
                      fontSize="3xl"
                      fontWeight="semibold"
                      mb="3"
                    >
                      {clientData.company_name}
                    </Text>

                    <Text lineHeight="1.2" fontSize="lg" color="gray.600">
                      {clientData.additional_details.contact_name}
                    </Text>
                    <Text lineHeight="1.2" fontSize="md" color="gray.600">
                      {clientData.additional_details.contact_email}
                    </Text>

                    {activityIsLoading ? (
                      <Flex>
                        <Spinner />
                      </Flex>
                    ) : activityIsSuccess ? (
                      <>
                        {activityData &&
                        activityData.filter(
                          (a) =>
                            moment(a.created_at).format("YYYY-MM-DD") ==
                            moment().format("YYYY-MM-DD")
                        ).length > 0 ? (
                          <>
                            <Flex mt="3">
                              <Box
                                borderRadius="full"
                                w="15px"
                                h="15px"
                                bg="green.500"
                                shadow="md"
                                mr="3"
                              />
                              <Text fontSize="sm" color="gray.500">
                                {activityData[0].count} zrealizowane
                              </Text>
                            </Flex>
                          </>
                        ) : (
                          <Flex mt="3">
                            <Box
                              borderRadius="full"
                              w="15px"
                              h="15px"
                              bg="red.500"
                              shadow="md"
                              mr="3"
                            />
                            <Text fontSize="sm" color="gray.500">
                              Brak dzisiejszej aktywności
                            </Text>
                          </Flex>
                        )}
                      </>
                    ) : (
                      <Flex mt="3">
                        <Box
                          borderRadius="full"
                          w="15px"
                          h="15px"
                          bg="red.500"
                          shadow="md"
                          mr="3"
                        />
                        <Text fontSize="sm" color="gray.500">
                          Brak dzisiejszej aktywności
                        </Text>
                      </Flex>
                    )}
                  </Flex>
                  <Flex d="column" mt="4">
                    <Flex align="center" mb="3">
                      <Text fontSize="sm" fontWeight="bold">
                        Szczegółowe informacje
                      </Text>

                      <EditClientModal client={clientData} />
                    </Flex>

                    <Flex d="column" mt="5">
                      <Text fontSize="sm" color="gray.600">
                        Status aktywności
                      </Text>
                      <Flex mt="2">
                        {clientData.is_active == 1 ? (
                          <Box p="1" borderRadius="lg" bg="green.200">
                            <Text
                              color="green.600"
                              fontSize="xs"
                              fontWeight="semibold"
                            >
                              Aktywny
                            </Text>
                          </Box>
                        ) : (
                          <Box p="1" borderRadius="lg" bg="red.200">
                            <Text
                              color="red.600"
                              fontSize="xs"
                              fontWeight="semibold"
                            >
                              Nieaktywny
                            </Text>
                          </Box>
                        )}
                      </Flex>
                    </Flex>

                    <Flex d="column" mt="5">
                      <Flex>
                        <Text fontSize="sm" color="gray.600">
                          Numer NIP
                        </Text>

                        {clientIsSuccess && clientData.additional_details && (
                          <CreateTaxIdentificatorModal
                            client_additional={clientData.additional_details.pk}
                          />
                        )}
                      </Flex>
                      <Text fontSize="sm">
                        {taxIdentificatorsIsLoading ? (
                          <Spinner />
                        ) : (
                          taxIdentificatorsIsSuccess &&
                          taxIdentificatorsData &&
                          taxIdentificatorsData.map((tax) => (
                            <TaxIdentificator tax={tax} />
                          ))
                        )}
                      </Text>
                    </Flex>

                    <Flex d="column" mt="5">
                      <Text fontSize="sm" color="gray.600">
                        Osoba kontaktowa
                      </Text>
                      <Text fontSize="sm">
                        {clientData.additional_details.contact_name}
                      </Text>
                      <Text fontSize="sm">
                        {clientData.additional_details.contact_phone}
                      </Text>
                      <Text fontSize="sm">
                        {clientData.additional_details.contact_place}
                      </Text>
                    </Flex>

                    <Flex d="column" mt="5">
                      <Text fontSize="sm" color="gray.600">
                        Data fakturowania
                      </Text>
                      <Text fontSize="sm">
                        {clientData.additional_details.invoice_date} dzień
                        miesiąca
                      </Text>
                      <Text fontSize="sm">
                        {moment()
                          .startOf("month")
                          .add(
                            clientData.additional_details.invoice_date - 1,
                            "days"
                          )
                          .format("YYYY-MM-DD")}
                      </Text>
                      <Text fontSize="sm">
                        {moment()
                          .locale("pl")
                          .to(
                            moment()
                              .startOf("month")
                              .add(
                                clientData.additional_details.invoice_date - 1,
                                "days"
                              )
                          )}
                      </Text>
                    </Flex>

                    <Flex d="column" mt="5">
                      <Text fontSize="sm" color="gray.600">
                        Cena netto za jednostkę
                      </Text>
                      <Text fontSize="sm">
                        {clientData.additional_details.price_per_unit} PLN
                      </Text>
                      <Text fontSize="sm">
                        {clientData.additional_details.price_per_unit *
                          clientData.available_units}{" "}
                        PLN łącznie
                      </Text>
                    </Flex>

                    <Flex mt="5">
                      <Stack isInline>
                        <Text fontSize="sm" color="gray.600">
                          Priorytet
                        </Text>
                        {clientData.additional_details.priority == 3 && (
                          <Box
                            d="inline-block"
                            px="2"
                            py="1"
                            borderRadius="lg"
                            bg="red.200"
                          >
                            <Text
                              color="red.600"
                              fontSize="xs"
                              fontWeight="semibold"
                            >
                              Ważny
                            </Text>
                          </Box>
                        )}
                        {clientData.additional_details.priority == 2 && (
                          <Box
                            d="inline-block"
                            px="2"
                            py="1"
                            borderRadius="lg"
                            bg="orange.200"
                          >
                            <Text
                              color="orange.600"
                              fontSize="xs"
                              fontWeight="semibold"
                            >
                              Średni
                            </Text>
                          </Box>
                        )}
                        {clientData.additional_details.priority == 1 && (
                          <Box
                            d="inline-block"
                            px="2"
                            py="1"
                            borderRadius="lg"
                            bg="green.200"
                          >
                            <Text
                              color="green.600"
                              fontSize="xs"
                              fontWeight="semibold"
                            >
                              Niski
                            </Text>
                          </Box>
                        )}
                      </Stack>
                    </Flex>
                  </Flex>
                  <Flex mt="5">
                    <Stack isInline>
                      <Text fontSize="sm" color="gray.600">
                        Status
                      </Text>

                      {clientData.additional_details.status == 1 && (
                        <Box
                          d="inline-block"
                          px="2"
                          py="1"
                          borderRadius="lg"
                          bg="red.200"
                        >
                          <Text
                            color="red.600"
                            fontSize="xs"
                            fontWeight="semibold"
                          >
                            Odmowa
                          </Text>
                        </Box>
                      )}

                      {clientData.additional_details.status == 2 && (
                        <Box
                          d="inline-block"
                          px="2"
                          py="1"
                          borderRadius="lg"
                          bg="green.200"
                        >
                          <Text
                            color="green.600"
                            fontSize="xs"
                            fontWeight="semibold"
                          >
                            Aktualny klient
                          </Text>
                        </Box>
                      )}

                      {clientData.additional_details.status == 3 && (
                        <Box
                          d="inline-block"
                          px="2"
                          py="1"
                          borderRadius="lg"
                          bg="yellow.200"
                        >
                          <Text
                            color="yellow.600"
                            fontSize="xs"
                            fontWeight="semibold"
                          >
                            Do potencjalnego kontaktu
                          </Text>
                        </Box>
                      )}

                      {clientData.additional_details.status == 4 && (
                        <Box
                          d="inline-block"
                          px="2"
                          py="1"
                          borderRadius="lg"
                          bg="yellow.200"
                        >
                          <Text
                            color="yellow.600"
                            fontSize="xs"
                            fontWeight="semibold"
                          >
                            Były klient
                          </Text>
                        </Box>
                      )}

                      {clientData.additional_details.status == 5 && (
                        <Box
                          d="inline-block"
                          px="2"
                          py="1"
                          borderRadius="lg"
                          bg="blue.200"
                        >
                          <Text
                            color="blue.600"
                            fontSize="xs"
                            fontWeight="semibold"
                          >
                            Demo
                          </Text>
                        </Box>
                      )}

                      {clientData.additional_details.status == 6 && (
                        <Box
                          d="inline-block"
                          px="2"
                          py="1"
                          borderRadius="lg"
                          bg="blue.200"
                        >
                          <Text
                            color="blue.600"
                            fontSize="xs"
                            fontWeight="semibold"
                          >
                            Po prezentacji
                          </Text>
                        </Box>
                      )}

                      {clientData.additional_details.status == 0 && (
                        <Box
                          d="inline-block"
                          px="2"
                          py="1"
                          borderRadius="lg"
                          bg="blue.200"
                        >
                          <Text
                            color="blue.600"
                            fontSize="xs"
                            fontWeight="semibold"
                          >
                            W trakcie rozmów
                          </Text>
                        </Box>
                      )}
                    </Stack>
                  </Flex>
                </Flex>
                <Flex direction="column" w={["100%", "100%", "100%", "70%"]}>
                  <Tabs>
                    <TabList>
                      <Tab>Konfiguracja</Tab>
                      <Tab>Aktywność klienta</Tab>
                    </TabList>

                    <TabPanels>
                      <TabPanel>
                        <Flex d="column">
                          <Flex align="center" py="2">
                            <Text fontSize="sm" fontWeight="bold">
                              Aktualna konfiguracja
                            </Text>
                            <EditConfigModal client={clientData} />
                          </Flex>

                          <SimpleGrid
                            columns={[1, 1, 1, 5]}
                            bg="white"
                            p="5"
                            borderRadius="lg"
                            spacing="5"
                          >
                            <Flex d="column">
                              <Text fontSize="md" color="gray.600">
                                Dostępne checklisty
                              </Text>
                              <Text fontSize="md">
                                {clientData.available_checklists}
                              </Text>
                            </Flex>
                            <Flex d="column">
                              <Text fontSize="md" color="gray.600">
                                Dostępni użytkownicy
                              </Text>
                              <Text fontSize="md">
                                {clientData.available_users}
                              </Text>
                            </Flex>
                            <Flex d="column">
                              <Text fontSize="md" color="gray.600">
                                Dostępne sloty email
                              </Text>
                              <Text fontSize="md">
                                {clientData.available_report_emails}
                              </Text>
                            </Flex>
                            <Flex d="column">
                              <Text fontSize="md" color="gray.600">
                                Dostępne jednostki
                              </Text>
                              <Text fontSize="md">
                                {clientData.available_units}
                              </Text>
                            </Flex>
                            <Flex d="column">
                              <Text fontSize="md" color="gray.600">
                                Dostępna jakość zdjęć
                              </Text>
                              <Text fontSize="md">
                                {clientData.available_photo_quaility}
                              </Text>
                            </Flex>
                          </SimpleGrid>
                        </Flex>

                        <Flex d="column" mt="5">
                          <Flex align="center" py="2">
                            <Text fontSize="sm" fontWeight="bold">
                              Lista faktur
                            </Text>

                            {invoicesIsSuccess && invoicesData && (
                              <Menu>
                                <MenuButton
                                  as={Button}
                                  size="xs"
                                  ml="auto"
                                  colorScheme="blue"
                                  variant="ghost"
                                >
                                  Akcja
                                </MenuButton>
                                <MenuList>
                                  <InvoiceReminderModal
                                    client={clientData}
                                    invoices={invoicesData}
                                  />
                                </MenuList>
                              </Menu>
                            )}
                          </Flex>
                          {invoicesIsLoading ? (
                            <Flex>
                              <Spinner />
                            </Flex>
                          ) : (
                            invoicesIsSuccess &&
                            invoicesData &&
                            (invoicesData.length > 0 ? (
                              <Flex
                                d="column"
                                bg="white"
                                p="5"
                                borderRadius="lg"
                                overflowX="auto"
                              >
                                <InvoicesClientTable invoices={invoicesData} />
                              </Flex>
                            ) : (
                              <Alert bg="gray.300">
                                <Text fontSize="xs" fontWeight="semibold">
                                  Brak faktur dla tego klienta
                                </Text>
                              </Alert>
                            ))
                          )}
                        </Flex>

                        <Flex d="column" mt="5">
                          <Flex align="center" py="2">
                            <Text fontSize="sm" fontWeight="bold">
                              Dokumenty
                            </Text>
                            <CreateDocumentModal client={clientData} />
                          </Flex>

                          {documentsIsLoading ? (
                            <Flex>
                              <Spinner />
                            </Flex>
                          ) : (
                            documentsIsSuccess && (
                              <>
                                {documentsData.length > 0 ? (
                                  <SimpleGrid
                                    columns={[1, 1, 1, 5]}
                                    bg="white"
                                    p="5"
                                    borderRadius="xl"
                                    spacing="5"
                                  >
                                    {documentsData.map((document) => (
                                      <FileThumbnail
                                        title={document.title}
                                        url={document.document}
                                        created_at={document.created_at}
                                        description={document.description}
                                        id={document.pk}
                                        key={document.pk}
                                      />
                                    ))}
                                  </SimpleGrid>
                                ) : (
                                  <Alert bg="gray.300">
                                    <Text fontSize="xs" fontWeight="semibold">
                                      Brak dokumentów dla tego klienta
                                    </Text>
                                  </Alert>
                                )}
                              </>
                            )
                          )}
                        </Flex>

                        <Flex d="column" mt="5">
                          <Flex align="center" py="2">
                            <Text fontSize="sm" fontWeight="bold">
                              Aktywność klienta
                            </Text>
                            <Button
                              variant="ghost"
                              color="gray.700"
                              size="sm"
                              ml="auto"
                            >
                              Edytuj
                            </Button>
                          </Flex>

                          {changesIsLoading ? (
                            <Flex>
                              <Spinner />
                            </Flex>
                          ) : (
                            changesIsSuccess && (
                              <>
                                {changesData.length > 0 ? (
                                  <Flex
                                    d="column"
                                    bg="white"
                                    p="5"
                                    borderRadius="lg"
                                    overflowX="auto"
                                  >
                                    <Flex>
                                      <Flex px="3" align="center" w="50%">
                                        <Text fontSize="xs" color="gray.600">
                                          Aktywność
                                        </Text>
                                      </Flex>
                                      <Flex px="3" align="center" w="25%">
                                        <Text fontSize="xs" color="gray.600">
                                          Data utworzenia
                                        </Text>
                                      </Flex>
                                      <Flex px="3" align="center" w="25%">
                                        <Text fontSize="xs" color="gray.600">
                                          Autor aktywności
                                        </Text>
                                      </Flex>
                                    </Flex>
                                    {changesData
                                      .sort((a, b) =>
                                        a.created_at > b.created_at ? -1 : 1
                                      )
                                      .map((change) => (
                                        <Flex key={change.pk}>
                                          <Flex
                                            px="3"
                                            py="0.5"
                                            align="center"
                                            w="50%"
                                          >
                                            <Text fontSize="sm">
                                              {change.content}
                                            </Text>
                                          </Flex>
                                          <Flex
                                            px="3"
                                            py="0.5"
                                            align="center"
                                            w="25%"
                                          >
                                            <Text fontSize="sm">
                                              {moment(change.created_at).format(
                                                "YYYY-MM-DD H:mm"
                                              )}
                                            </Text>
                                          </Flex>
                                          <Flex
                                            px="3"
                                            py="0.5"
                                            align="center"
                                            w="25%"
                                          >
                                            <Text fontSize="sm">
                                              {
                                                change.created_by_details
                                                  .first_name
                                              }{" "}
                                              {
                                                change.created_by_details
                                                  .last_name
                                              }
                                            </Text>
                                          </Flex>
                                        </Flex>
                                      ))}
                                  </Flex>
                                ) : (
                                  <Alert bg="gray.300">
                                    <Text fontSize="xs" fontWeight="semibold">
                                      Brak aktywności dla tego klienta
                                    </Text>
                                  </Alert>
                                )}
                              </>
                            )
                          )}
                        </Flex>
                      </TabPanel>
                      <TabPanel>
                        {activityIsSuccess && activityData.length > 0 ? (
                          <>
                            {activityData && (
                              <Flex
                                bg="white"
                                p="5"
                                borderRadius="lg"
                                d="column"
                              >
                                <ActivityTable activities={activityData} />
                              </Flex>
                            )}
                          </>
                        ) : (
                          <Alert bg="gray.300">
                            <Text fontSize="xs" fontWeight="semibold">
                              Brak aktywności dla tego klienta
                            </Text>
                          </Alert>
                        )}
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </Flex>
              </Flex>
            </>
          )
        )}
      </Flex>
    </Flex>
  );
};

export default ClientDetail;
