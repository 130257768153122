import React from "react";
import PropTypes from "prop-types";

import { Flex } from "@chakra-ui/react";

import SidebarLink from "./SidebarLink";
import { FiHome, FiFile, FiSmile } from "react-icons/fi";

const Sidebar = () => {
  const menu = [
    {
      icon: FiHome,
      href: "/",
      title: "Strona główna",
    },
    {
      icon: FiFile,
      href: "/faktury",
      title: "Faktury",
    },
    {
      icon: FiSmile,
      href: "/community",
      title: "community",
    },
  ];
  return (
    <Flex
      w="90px"
      py="30px"
      direction="column"
      align="center"
      h="100vh"
      pos="fixed"
      bg="white"
      borderRight="1px"
      borderRightColor="gray.200"
      shadow="sm"
    >
      {menu.map((m) => (
        <SidebarLink icon={m.icon} href={m.href} key={m.href} />
      ))}
    </Flex>
  );
};

Sidebar.propTypes = {};

export default Sidebar;
