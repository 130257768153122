import React, { useState, useEffect } from "react";

import { Flex, Box, Text, Button, useToast } from "@chakra-ui/react";

import Stepper from "react-stepper-horizontal";
import Select from "react-select";
import { useFormik } from "formik";
import { useMutation, useQueryClient } from "react-query";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";

import { FiPlus, FiMinusCircle, FiPlusCircle } from "react-icons/fi";

import LogoDropzone from "../../components/dropzones/LogoDropzone";

import ClientBasic from "./Form/ClientBasic";
import ClientInfo from "./Form/ClientInfo";
import ClientConfig from "./Form/ClientConfig";
import ClientSummary from "./Form/ClientSummary";

import { storeClient } from "../../api/client/client";

const ClientCreate = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [files, setFiles] = useState([]);

  const toast = useToast();
  let history = useHistory();

  const priorityOptions = [
    {
      label: "Niski",
      value: "1",
    },
    {
      label: "Średni",
      value: "2",
    },
    {
      label: "Wysoki",
      value: "3",
    },
  ];

  const ClientSchema = Yup.object().shape({
    company: Yup.string()
      .required("To pole jest wymagane!")
      .min(3, "Za mała liczba znaków!")
      .max(200, "Przekroczono liczbę znaków!"),
    name: Yup.string()
      .required("To pole jest wymagane!")
      .min(3, "Za mała liczba znaków!")
      .max(100, "Przekroczono liczbę znaków!"),
    subdomain: Yup.string()
      .required("To pole jest wymagane!")
      .min(2, "Za mała liczba znaków!")
      .max(63, "Przekroczono liczbę znaków!"),
    username: Yup.string()
      .required("To pole jest wymagane!")
      .min(2, "Za mała liczba znaków!")
      .max(100, "Przekroczono liczbę znaków!"),
    client_name: Yup.string()
      .required("To pole jest wymagane!")
      .max(100, "Przekroczono liczbę znaków!")
      .min(5, "Za mała liczba znaków!"),
    email: Yup.string()
      .email("Nieprawidłowy format!")
      .required("To pole jest wymagane!")
      .max(200, "Przekroczono liczbę znaków!")
      .min(2, "Za mała liczba znaków!"),
    phone: Yup.string()
      .required("To pole jest wymagane!")
      .max(200, "Przekroczono liczbę znaków!")
      .min(2, "Za mała liczba znaków!"),
    city: Yup.string()
      .required("To pole jest wymagane!")
      .max(200, "Przekroczono liczbę znaków!")
      .min(2, "Za mała liczba znaków!"),
    nip: Yup.string()
      .required("To pole jest wymagane!")
      .max(12, "Przekroczono liczbę znaków!")
      .min(10, "Za mała liczba znaków!"),
    priority: Yup.number()
      .required("To pole jest wymagane!")
      .typeError("To pole musi być liczbą!"),
    invoice_day: Yup.number()
      .required("To pole jest wymagane!")
      .typeError("To pole musi być liczbą!"),
    price_per_unit: Yup.number()
      .required("To pole jest wymagane!")
      .typeError("To pole musi być liczbą!"),
    demo_end: Yup.date().typeError("To pole musi być data!"),
    available_checklists: Yup.number()
      .required("To pole jest wymagane!")
      .typeError("To pole musi być liczbą!"),
    available_users: Yup.number()
      .required("To pole jest wymagane!")
      .typeError("To pole musi być liczbą!"),
    available_report_emails: Yup.number()
      .required("To pole jest wymagane!")
      .typeError("To pole musi być liczbą!"),
    available_units: Yup.number()
      .required("To pole jest wymagane!")
      .typeError("To pole musi być liczbą!"),
    available_photo_quaility: Yup.number()
      .required("To pole jest wymagane!")
      .typeError("To pole musi być liczbą!"),
    file: Yup.string().required("To pole jest wymagane!"),
  });

  const storeClientMutation = useMutation(storeClient, {
    onSuccess: () => {
      toast({
        title: "Sukces!",
        position: "top-right",
        description: "Klient został utworzony!",
        status: "success",
        duration: 4000,
        isClosable: true,
      });

      history.push("/");
    },
    onError: () =>
      toast({
        title: "Uwaga, wystapił problem",
        position: "top-right",
        description: "Spróbuj ponownie później!",
        status: "error",
        duration: 4000,
        isClosable: true,
      }),
  });

  const clientFormik = useFormik({
    initialValues: {
      company: "",
      name: "",
      subdomain: "",
      username: "admin",
      client_name: "",
      email: "",
      phone: "",
      nip: "",
      city: "",
      priority: 2,
      invoice_day: "",
      price_per_unit: 99,
      demo_day: "",
      available_checklists: 0,
      available_users: 0,
      available_report_emails: 0,
      available_units: 15,
      available_photo_quaility: 0,
      file: "",
    },
    validationSchema: ClientSchema,
    onSubmit: (values) => {
      storeClientMutation.mutate(values);
    },
  });

  const handleFormSubmit = () => {
    clientFormik.submitForm();
  };

  const handleNextStep = () => {
    if (activeStep < 3) {
      setActiveStep(activeStep + 1);
    }
  };

  const handlePrevStep = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };

  useEffect(() => {
    clientFormik.setFieldValue("file", files[0]);
  }, [files]);

  return (
    <Flex direction="column" w="full">
      <Flex>
        <Box>
          <Text fontSize="2xl" color="primary.black" fontWeight="regular">
            Tworzenie nowego klienta
          </Text>
          <Text color="gray.600">
            Poniżej znajdują się informacje o Twoich dostępnych usługach w
            mycheck
          </Text>
        </Box>
        <Box ml="auto"></Box>
      </Flex>
      <Flex d="column">
        <Stepper
          completeBarColor="#2ec4b6"
          circleFontSize="6"
          completeColor="#2ec49a"
          activeColor="#ffc950"
          steps={[
            {
              title: "Dane podstawowe",
            },
            {
              title: "Dane klienta",
            },
            { title: "Konfiguracja" },
            { title: "Podsumowanie" },
          ]}
          activeStep={activeStep}
        />

        <Flex bg="white" borderRadius="xl" my="4" d="column">
          {activeStep == 0 && (
            <ClientBasic
              files={files}
              setFiles={setFiles}
              clientFormik={clientFormik}
            />
          )}
          {activeStep == 1 && <ClientInfo clientFormik={clientFormik} />}
          {activeStep == 2 && <ClientConfig clientFormik={clientFormik} />}
          {activeStep == 3 && <ClientSummary clientFormik={clientFormik} />}
          <Flex justify="center" bg="gray.100" p="6" w="full">
            <Button
              bgColor="yellow.300"
              size="sm"
              onClick={() => handlePrevStep()}
              mr="4"
            >
              Poprzedni
            </Button>
            <Button
              bgColor="yellow.300"
              size="sm"
              onClick={() => handleNextStep()}
              ml="4"
              mr="4"
            >
              Następny
            </Button>

            <Button
              colorScheme="green"
              size="sm"
              onClick={() => handleFormSubmit()}
              isLoading={storeClientMutation.isLoading}
              ml="4"
            >
              Utwórz
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ClientCreate;
