import axios from "axios";
import { getToken } from "../../utils/GetToken";
import { API } from "../../utils/GetSystemVersion";

export const fetchClients = async () => {
  const token = getToken();

  const { data } = await axios.get(`${API()}/clients/`, {
    headers: { Authorization: `Token ${token}` },
  });
  return data;
};

export const fetchInvoices = async () => {
  const token = getToken();

  const { data } = await axios.get(`${API()}/clients/invoices`, {
    headers: { Authorization: `Token ${token}` },
  });
  return data;
};

export const fetchClientInvoices = async (params) => {
  const token = getToken();
  const id = params["queryKey"][1];

  const { data } = await axios.get(`${API()}/clients/${id}/invoices`, {
    headers: { Authorization: `Token ${token}` },
  });
  return data;
};

export const fetchClient = async (params) => {
  const id = params["queryKey"][1];
  const token = getToken();

  const { data } = await axios.get(`${API()}/clients/${id}`, {
    headers: { Authorization: `Token ${token}` },
  });
  return data;
};

export const fetchClientActivity = async (params) => {
  const id = params["queryKey"][1];
  const token = getToken();

  const { data } = await axios.get(`${API()}/clients/${id}/activity`, {
    headers: { Authorization: `Token ${token}` },
  });
  return data;
};

export const fetchClientChanges = async (params) => {
  const id = params["queryKey"][1];
  const token = getToken();

  const { data } = await axios.get(`${API()}/clients/${id}/changes`, {
    headers: { Authorization: `Token ${token}` },
  });
  return data;
};

export const updateClientConfig = async (values) => {
  const token = getToken();

  const form = new FormData();
  form.set("available_checklists", values.available_checklists);
  form.set("available_users", values.available_users);
  form.set("available_report_emails", values.available_report_emails);
  form.set("available_units", values.available_units);
  form.set("available_photo_quaility", values.available_photo_quaility);

  const { data } = await axios({
    method: "patch",
    url: `${API()}/clients/${values.id}/config/update`,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${token}`,
    },
    data: form,
  });
  return data;
};

export const unpaidInvoiceClientReminder = async (values) => {
  const token = getToken();

  const form = new FormData();

  values.invoices.forEach((item) => {
    form.append("invoices", item);
  });

  const { data } = await axios({
    method: "post",
    url: `${API()}/clients/${values.id}/invoices/reminder`,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${token}`,
    },
    data: form,
  });
  return data;
};

export const updateClient = async (values) => {
  const token = getToken();

  const form = new FormData();
  form.set("name", values.name);
  form.set("company_name", values.company_name);

  const { data } = await axios({
    method: "patch",
    url: `${API()}/clients/${values.id}/update`,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${token}`,
    },
    data: form,
  });
  return data;
};

export const updateClientActive = async (values) => {
  const token = getToken();

  const form = new FormData();
  form.set("is_active", values.is_active);

  const { data } = await axios({
    method: "patch",
    url: `${API()}/clients/${values.id}/active`,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${token}`,
    },
    data: form,
  });
  return data;
};

export const updateClientAdditional = async (values) => {
  const token = getToken();

  const form = new FormData();
  form.set("contact_name", values.contact_name);
  form.set("contact_phone", values.contact_phone);
  form.set("contact_email", values.contact_email);
  form.set("client_nip", values.client_nip);
  form.set("priority", values.priority);
  form.set("status", values.status);
  form.set("invoice_date", values.invoice_date);
  form.set("price_per_unit", values.price_per_unit);

  const { data } = await axios({
    method: "patch",
    url: `${API()}/clients/${values.id}/additional/update`,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${token}`,
    },
    data: form,
  });
  return data;
};

export const storeClientDocument = async (values) => {
  const token = getToken();

  const form = new FormData();
  form.set("client", values.client);
  form.set("document", values.file);
  form.set("title", values.title);
  form.set("description", values.description);

  const { data } = await axios({
    method: "post",
    url: `${API()}/clients/document/create`,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${token}`,
    },
    data: form,
  });
  return data;
};

export const updateClientDocument = async (values) => {
  const token = getToken();

  const form = new FormData();
  form.set("title", values.title);
  form.set("description", values.description);

  const { data } = await axios({
    method: "patch",
    url: `${API()}/clients/document/${values.id}/update`,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${token}`,
    },
    data: form,
  });
  return data;
};

export const fetchClientDocuments = async (params) => {
  const token = getToken();
  const id = params["queryKey"][1];

  const { data } = await axios.get(`${API()}/clients/${id}/documents`, {
    headers: { Authorization: `Token ${token}` },
  });
  return data;
};

export const removeClientDocument = async (id) => {
  const token = getToken();

  const { data } = await axios({
    method: "delete",
    url: `${API()}/clients/document/${id}/delete`,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${token}`,
    },
  });
  return data;
};

export const storeClient = async (values) => {
  const token = getToken();
  const FileDownload = require("js-file-download");

  const form = new FormData();
  form.set("company", values.company);
  form.set("name", values.name);
  form.set("subdomain", values.subdomain);
  form.set("username", values.username);
  form.set("client_name", values.client_name);
  form.set("email", values.email);
  form.set("phone", values.phone);
  form.set("nip", values.nip);
  form.set("city", values.city);
  form.set("priority", values.priority);
  form.set("invoice_day", values.invoice_day);
  form.set("price_per_unit", values.price_per_unit);
  form.set("available_checklists", values.available_checklists);
  form.set("available_users", values.available_users);
  form.set("available_report_emails", values.available_report_emails);
  form.set("available_units", values.available_units);
  form.set("available_photo_quaility", values.available_photo_quaility);
  form.set("file", values.file);
  form.set("demo_end", values.demo_end);

  const { data } = await axios({
    method: "post",
    url: `${API()}/clients/create`,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${token}`,
    },
    responseType: "blob",
    data: form,
  }).then((response) => {
    FileDownload(response.data, "test.pdf");

    return response.data;
  });
  return data;
};

export const storeClientAccess = async (params) => {
  const token = getToken();
  const id = params["id"];

  const { data } = await axios({
    method: "post",
    url: `${API()}/clients/${id}/access/create`,
    headers: {
      Authorization: `Token ${token}`,
    },
  });
  return data;
};

export const fetchClientTaxIdentificators = async (params) => {
  const id = params["queryKey"][1];
  const token = getToken();

  const { data } = await axios.get(`${API()}/clients/${id}/tax`, {
    headers: { Authorization: `Token ${token}` },
  });
  return data;
};

export const storeClientTaxIdentificator = async (values) => {
  const token = getToken();
  console.log(values);

  const form = new FormData();
  form.set("client", values.client_additional);
  form.set("client_nip", values.tax_id);

  const { data } = await axios({
    method: "post",
    url: `${API()}/clients/tax/create`,
    data: form,
    headers: {
      Authorization: `Token ${token}`,
    },
  });
  return data;
};

export const deleteClientTaxIdentificator = async (pk) => {
  const token = getToken();

  const { data } = await axios({
    method: "delete",
    url: `${API()}/clients/tax/${pk}/delete`,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${token}`,
    },
  });
  return data;
};
