import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

import { Flex, Text } from "@chakra-ui/react";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  height: 50,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

const dropzone = {
  height: "100%",
};

const LogoDropzone = (props) => {
  const { setFiles, files } = props;
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: ["image/*"],
    maxSize: 1000000,
    maxFiles: 1,
    multiple: false,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const errors_custom = [
    {
      code: "file-invalid-type",
      message: "Plik musi być zdjęciem",
    },
    {
      code: "file-too-large",
      message: "Dopuszczalna wielkość pliku to 10MB",
    },
  ];

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} />
      </div>
    </div>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) =>
    errors.map((e) => (
      <Text color="red.400" key={e.code}>
        {errors_custom.filter((error) => error.code == e.code).length > 0
          ? errors_custom.filter((error) => error.code == e.code)[0].message
          : `Niezidentyfikowany błąd ${e.code}`}
      </Text>
    ))
  );

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <>
      <Flex
        direction="column"
        h="full"
        justify="center"
        align="center"
        py="15px"
        border="2px dashed"
        borderColor="gray.400"
        cursor="pointer"
        {...getRootProps({ className: "dropzone" })}
      >
        <input {...getInputProps()} />
        <Text textAlign="center" color="gray.600" fontSize="sm">
          Przeciągnij i upuść plik, lub naciśnij aby wybrać
        </Text>
        {fileRejectionItems}
        <aside style={thumbsContainer}>{thumbs}</aside>
      </Flex>
    </>
  );
};

export default LogoDropzone;
