import React, { useState, useEffect } from "react";

import { useQuery } from "react-query";
import { FiPlusCircle } from "react-icons/fi";

import { Text, Flex, Box, Button, Link, useToast } from "@chakra-ui/react";
import { fetchCategory } from "../../api/community/question";

import QuestionCreate from "../../components/pagesComponents/community/QuestionCreate";

const CommunityDetail = (props) => {
  const id = props.match.params.id;

  const toast = useToast();

  const [newQuestion, setNewQuestion] = useState(0);
  const [newQuestionElements, setNewQuestionElements] = useState([]);

  const {
    isLoading: categoryIsLoading,
    data: categoryData,
    isSuccess: categoryIsSuccess,
  } = useQuery(["fetchCategory", id], fetchCategory, {
    retry: false,
    refetchOnWindowFocus: false,
  });

  const handleNewQuestion = () => {
    if (newQuestion == 0) {
      setNewQuestion(1);
    } else {
      toast({
        title: "Uwaga",
        position: "top-right",
        description: "Zapisz ostatnie pytanie, aby dodać kolejne!",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    let items = [];
    for (var i = 0; i < newQuestion; i++) {
      items.push({ item: i });
    }

    setNewQuestionElements(items);
  }, [newQuestion]);

  return (
    <Flex direction="column" w="full">
      <Flex>
        <Box>
          <Text fontSize="2xl" color="primary.black" fontWeight="regular">
            <Text as="span" fontWeight="semibold">
              Baza pytań {categoryIsSuccess && categoryData.name}
            </Text>
          </Text>
        </Box>

        <Box ml="auto">
          <Link href="/community">
            <Button variant="ghost" size="sm">
              Wróć
            </Button>
          </Link>
        </Box>
      </Flex>
      <Flex d="column">
        <Flex
          bg="white"
          p="5"
          my="4"
          borderRadius="lg"
          shadow="sm"
          align="center"
          d="column"
        >
          <Flex>
            <Text fontSize="lg" fontWeight="semibold">
              Lista pytań
            </Text>

            <Box ml="auto">
              <Button colorScheme="green" onClick={() => handleNewQuestion()}>
                <Box as={FiPlusCircle} />
              </Button>
            </Box>
          </Flex>

          <Flex d="column">
            <Flex direction="row" w="full" my="1">
              <Flex
                w="40%"
                fontSize="sm"
                color="gray.600"
                fontWeight="semibold"
              >
                Treść pytania
              </Flex>
              <Flex
                w="10%"
                fontSize="sm"
                color="gray.600"
                fontWeight="semibold"
                px="5"
                justifyContent="center"
              >
                Logiczna
              </Flex>
              <Flex
                w="10%"
                fontSize="sm"
                color="gray.600"
                fontWeight="semibold"
                px="5"
                justifyContent="center"
              >
                Tekstowa
              </Flex>
              <Flex
                w="10%"
                fontSize="sm"
                color="gray.600"
                fontWeight="semibold"
                px="5"
                justifyContent="center"
              >
                Zdjęcie
              </Flex>
              <Flex
                w="10%"
                fontSize="sm"
                color="gray.600"
                fontWeight="semibold"
                px="5"
                justifyContent="center"
              >
                Natychmiastowe
              </Flex>
              <Flex
                w="10%"
                fontSize="sm"
                color="gray.600"
                fontWeight="semibold"
                px="5"
                justifyContent="center"
              >
                Czasowe
              </Flex>
            </Flex>
            {categoryIsSuccess && (
              <>
                {categoryData.questions.length > 0 && (
                  <>
                    {categoryData.questions.map((question) => (
                      <QuestionCreate
                        category={categoryData}
                        question={question}
                        key={question.pk}
                      />
                    ))}
                  </>
                )}

                {newQuestionElements.map((element) => (
                  <QuestionCreate
                    category={categoryData}
                    setNewQuestion={setNewQuestion}
                    newQuestion={newQuestion}
                    key={element.item}
                  />
                ))}
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CommunityDetail;
