import React from "react";

import { connect } from "react-redux";
import { NavLink, Redirect } from "react-router-dom";
import { authLogin } from "../store/actions/auth";

import AnzuroLogo from "../assets/images/anzuro.png";
import MycheckLogo from "../assets/images/mycheck.png";

import LoginAnimation from "../components/animations/LoginAnimation";

import {
  Flex,
  Text,
  Image,
  Box,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  InputGroup,
  InputLeftElement,
  Button,
  Alert,
} from "@chakra-ui/react";

import { FiMail, FiLock } from "react-icons/fi";

class LoginForm extends React.Component {
  state = {
    email: "",
    password: "",
    buttonPressed: false,
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleButtonPressed = (status) => {
    this.setState({ buttonPressed: status });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.handleButtonPressed(true);
    const { email, password } = this.state;
    this.props.login(email, password);
  };

  componentDidUpdate(previousProps, previousState) {
    const { error } = this.props;
    if (previousProps.error !== error) {
      this.handleButtonPressed(false);
    }
  }

  render() {
    const { error, loading, token } = this.props;
    const { email, password } = this.state;

    if (token) {
      return <Redirect to="/" />;
    }

    return (
      <Flex>
        <Flex
          px="7%"
          w={["100%", "100%", "50%", "45%", "35%"]}
          h="100vh"
          position="relative"
          direction="column"
          justify="center"
        >
          <Flex position="absolute" top="5%">
            <Image src={MycheckLogo} h="35px" />
          </Flex>
          <Text fontSize="3xl">Logowanie</Text>
          <Text fontSize="sm" color="gray.600" mt="5">
            Wprowadź poniżej dane dostępowe i zaloguj się do swojego konta
          </Text>

          {error && error.non_field_errors && (
            <Flex m="0" mt="20px" w="full">
              {error.non_field_errors.map((error) => (
                <Text color="red.500" fontWeight="600">
                  {error}
                </Text>
              ))}
            </Flex>
          )}

          <form onSubmit={this.handleSubmit}>
            <Box mt="40px">
              <FormControl>
                <FormLabel htmlFor="email" fontSize="sm" fontWeight="semibold">
                  Adres e-mail
                </FormLabel>

                <InputGroup>
                  <InputLeftElement
                    children={<Box as={FiMail} color="gray.500" />}
                  />
                  <Input
                    type="email"
                    onChange={this.handleChange}
                    name="email"
                  />
                </InputGroup>
                <FormHelperText id="email-helper-text">
                  Wprowadź adres e-mail
                </FormHelperText>
              </FormControl>

              <FormControl mt="5">
                <FormLabel htmlFor="email" fontSize="sm" fontWeight="semibold">
                  Hasło
                </FormLabel>
                <InputGroup>
                  <InputLeftElement
                    children={<Box as={FiLock} color="gray.500" />}
                  />
                  <Input
                    name="password"
                    onChange={this.handleChange}
                    isInvalid={error && error.password && true}
                    type="password"
                  />
                </InputGroup>

                {error && (
                  <Text
                    color="red.500"
                    fontWeight="semibold"
                    fontSize="sm"
                    mt="2"
                  >
                    {error.password}
                  </Text>
                )}

                <FormHelperText id="email-helper-text">
                  Wprowadź hasło służące do autoryzacji
                </FormHelperText>
              </FormControl>

              <Button
                type="submit"
                w="100%"
                bg="primary.green"
                color="white"
                mt="35px"
                isLoading={this.state.buttonPressed}
              >
                Zaloguj
              </Button>
            </Box>
          </form>
        </Flex>
        <Flex
          w={["0%", "0%", "50%", "55%", "65%"]}
          bg="primary.green"
          align="center"
          justify="center"
          direction="row"
        >
          <Box w="90%">
            <LoginAnimation />
          </Box>
        </Flex>
      </Flex>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (email, password) => dispatch(authLogin(email, password)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
