import React, { useState } from "react";

import {
  Flex,
  Box,
  Text,
  Button,
  Input,
  FormControl,
  FormLabel,
  SimpleGrid,
} from "@chakra-ui/react";

import Select from "react-select";
import moment from "moment";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import pl from "date-fns/locale/pl";

import LogoDropzone from "../../../components/dropzones/LogoDropzone";

const ClientBasic = (props) => {
  const { clientFormik, files, setFiles } = props;
  registerLocale("pl", pl);

  const priorityOptions = [
    {
      label: "Niski",
      value: "1",
    },
    {
      label: "Średni",
      value: "2",
    },
    {
      label: "Wysoki",
      value: "3",
    },
  ];

  const handlePriorityField = (value) => {
    if (value) {
      clientFormik.setFieldValue("priority", value.value);
    }
  };

  const handleDemoDate = (value) => {
    clientFormik.setFieldValue("demo_end", moment(value).format("YYYY-MM-DD"));
  };

  return (
    <Flex justify="center" p="35px">
      <SimpleGrid w="70%" spacingX="30px" spacingY="15px" columns="2">
        <Flex direction="column" mb="2">
          <FormControl id="company" isRequired>
            <FormLabel fontWeight="semibold" fontSize="sm">
              Nazwa firmy
            </FormLabel>
            <Input
              type="text"
              name="company"
              placeholder="Wprowadź nazwę firmy"
              onChange={clientFormik.handleChange}
              value={clientFormik.values.company}
            />

            {clientFormik.errors.company && clientFormik.touched.company && (
              <Text mt="5px" color="red.500">
                {clientFormik.errors.company}
              </Text>
            )}
          </FormControl>
        </Flex>
        <Flex direction="column" mb="2">
          <FormControl id="name" isRequired>
            <FormLabel fontWeight="semibold" fontSize="sm">
              Skrócona nazwa firmy
            </FormLabel>
            <Input
              type="text"
              name="name"
              placeholder="Wprowadź skróconą nazwę firmy"
              onChange={clientFormik.handleChange}
              value={clientFormik.values.name}
            />

            {clientFormik.errors.name && clientFormik.touched.name && (
              <Text mt="5px" color="red.500">
                {clientFormik.errors.name}
              </Text>
            )}
          </FormControl>
        </Flex>
        <Flex direction="column" mb="2">
          <FormControl id="subdomain" isRequired>
            <FormLabel fontWeight="semibold" fontSize="sm">
              Nazwa subdomeny
            </FormLabel>
            <Input
              type="text"
              name="subdomain"
              placeholder="Wprowadź subdomenę mycheck.pl"
              onChange={clientFormik.handleChange}
              value={clientFormik.values.subdomain}
            />

            {clientFormik.errors.subdomain && clientFormik.touched.subdomain && (
              <Text mt="5px" color="red.500">
                {clientFormik.errors.subdomain}
              </Text>
            )}
          </FormControl>
        </Flex>
        <Flex direction="column" mb="2">
          <FormControl id="username" isRequired>
            <FormLabel fontWeight="semibold" fontSize="sm">
              Nazwa konta głównego
            </FormLabel>
            <Input
              type="text"
              name="username"
              value="admin"
              placeholder="Wprowadź nazwę konta głównego"
              onChange={clientFormik.handleChange}
              value={clientFormik.values.username}
            />

            {clientFormik.errors.username && clientFormik.touched.username && (
              <Text mt="5px" color="red.500">
                {clientFormik.errors.username}
              </Text>
            )}
          </FormControl>
        </Flex>
        <Flex direction="column" mb="2">
          <FormControl id="priority" isRequired>
            <FormLabel fontWeight="semibold" fontSize="sm">
              Priorytet
            </FormLabel>
            <Box
              as={Select}
              cacheOptions
              placeholder="Wybierz priorytet"
              isClearable={true}
              onChange={handlePriorityField}
              classNamePrefix="react-select"
              defaultValue={priorityOptions.filter(
                (option) => option.value == clientFormik.values.priority
              )}
              loadingMessage={() => "Wyszukiwanie.."}
              noOptionsMessage={() => "Nie znaleziono!"}
              options={priorityOptions}
              classNamePrefix={
                clientFormik.errors.priority
                  ? "react-select-invalid"
                  : "react-select"
              }
            />

            {clientFormik.errors.priority && (
              <Text mt="5px" color="red.500">
                {clientFormik.errors.priority}
              </Text>
            )}
          </FormControl>
        </Flex>
        <Flex direction="column" mb="2">
          <FormControl id="invoice_day" isRequired>
            <FormLabel fontWeight="semibold" fontSize="sm">
              Dzień miesiąca fakturowania
            </FormLabel>
            <Input
              type="text"
              name="invoice_day"
              placeholder="Wprowadź dzień miesiąca"
              onChange={clientFormik.handleChange}
              value={clientFormik.values.invoice_day}
            />

            {clientFormik.errors.invoice_day &&
              clientFormik.touched.invoice_day && (
                <Text mt="5px" color="red.500">
                  {clientFormik.errors.invoice_day}
                </Text>
              )}
          </FormControl>
        </Flex>
        <Flex direction="column" mb="2">
          <FormControl id="demo_end">
            <FormLabel fontWeight="semibold" fontSize="sm">
              Data końca wersji demo
            </FormLabel>
            <Input
              as={DatePicker}
              name="demo_end"
              placeholder="Wprowadź dzień miesiąca"
              onChange={handleDemoDate}
              value={clientFormik.values.demo_end}
              locale="pl"
            />

            {clientFormik.errors.demo_end && clientFormik.touched.demo_end && (
              <Text mt="5px" color="red.500">
                {clientFormik.errors.demo_end}
              </Text>
            )}
          </FormControl>
        </Flex>
        <Flex direction="column" mb="2">
          <FormControl id="demo_end" isRequired>
            <FormLabel fontWeight="semibold" fontSize="sm">
              Cerna netto za jednostkę [PLN]
            </FormLabel>
            <Input
              type="text"
              name="price_per_unit"
              placeholder="Wprowadź cenę netto"
              onChange={clientFormik.handleChange}
              value={clientFormik.values.price_per_unit}
            />

            {clientFormik.errors.price_per_unit &&
              clientFormik.touched.price_per_unit && (
                <Text mt="5px" color="red.500">
                  {clientFormik.errors.price_per_unit}
                </Text>
              )}
          </FormControl>
        </Flex>
        <Flex direction="column" mb="2">
          <FormControl id="file" isRequired>
            <FormLabel fontWeight="semibold" fontSize="sm">
              Logotyp
            </FormLabel>

            <LogoDropzone files={files} setFiles={setFiles} />

            {clientFormik.errors.file && clientFormik.submitCount > 0 && (
              <Text mt="5px" color="red.500">
                {clientFormik.errors.file}
              </Text>
            )}
          </FormControl>
        </Flex>
      </SimpleGrid>
    </Flex>
  );
};

export default ClientBasic;
