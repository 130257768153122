import React from "react";

import MycheckLogo from "../assets/images/mycheck.png";

import MobileMenu from "../components/Sidebar/MobileMenu";

import { Image, Button, Box, Flex } from "@chakra-ui/react";

const Navbar = (props) => {
  const { logout } = props;
  return (
    <Flex px="40px" py="20px" direction="row" align="center">
      <Image src={MycheckLogo} h="30px" />

      <Box ml="auto" d={["none", "none", "none", "block"]}>
        <Button variant="ghost" fontSize="sm" onClick={() => logout()}>
          Wyloguj się
        </Button>
      </Box>

      <Box d={["block", "block", "block", "none"]} ml="auto">
        <MobileMenu logout={logout} />
      </Box>
    </Flex>
  );
};

export default Navbar;
