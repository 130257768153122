import { theme } from "@chakra-ui/react";

const breakpoints = ["576px", "768px", "992px", "1200px", "1400px"];

breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];
breakpoints.xxl = breakpoints[4];

const mainTheme = {
  ...theme,
  fonts: {
    heading: "Avenir Next, sans-serif",
    body: "Open Sans, sans-serif",
    mono: "Menlo, monospace",
    lato: "Lato, sans-serif",
  },
  breakpoints,
  fontSizes: {
    xs: ".75rem",
    sm: ".875rem",
    base: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "4rem",
  },
  shadows: {
    base: "0px 0px 5px rgba(0, 0, 0, 0.08)",
    xs: "0 0 0 1px rgba(0, 0, 0, 0.05)",
    sm: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
    normal: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
    md: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    lg: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
    xl: "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
    "2xl": "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
    inner: "inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)",
    outline: "0 0 0 3px rgba(66, 153, 225, 0.5)",
    none: "none",
  },
  colors: {
    ...theme.colors,
    primary: {
      black: "#011627",
      gray: "#F6F7F8",
      green: "#2EC4B6",
      red: "#FF3366",
      blue: "#20A4F3",
      bg: "#f2f5f7",
    },
    gray: {
      100: "#F7FAFC",
      200: "#EDF2F7",
      300: "#E2E8F0",
      400: "#CBD5E0",
      500: "#A0AEC0",
      600: "#718096",
      700: "#4A5568",
      800: "#2D3748",
      900: "#1A202C",
    },
    red: {
      100: "#FFF5F5",
      200: "#FED7D7",
      300: "#FEB2B2",
      400: "#FC8181",
      500: "#F56565",
      600: "#E53E3E",
      700: "#C53030",
      800: "#9B2C2C",
      900: "#742A2A",
    },
    orange: {
      100: "#FFFAF0",
      200: "#FEEBC8",
      300: "#FBD38D",
      400: "#F6AD55",
      500: "#ED8936",
      600: "#DD6B20",
      700: "#C05621",
      800: "#9C4221",
      900: "#7B341E",
    },
    yelllow: {
      100: "#FFFFF0",
      200: "#FEFCBF",
      300: "#FAF089",
      400: "#F6E05E",
      500: "#ECC94B",
      600: "#D69E2E",
      700: "#B7791F",
      800: "#975A16",
      900: "#744210",
    },
    green: {
      100: "#F0FFF4",
      200: "#C6F6D5",
      300: "#9AE6B4",
      400: "#68D391",
      500: "#48BB78",
      600: "#38A169",
      700: "#2F855A",
      800: "#276749",
      900: "#22543D",
    },
    teal: {
      100: "#E6FFFA",
      200: "#B2F5EA",
      300: "#81E6D9",
      400: "#4FD1C5",
      500: "#38B2AC",
      600: "#319795",
      700: "#2C7A7B",
      800: "#285E61",
      900: "#234E52",
    },
    blue: {
      100: "#EBF8FF",
      200: "#BEE3F8",
      300: "#90CDF4",
      400: "#63B3ED",
      500: "#4299E1",
      600: "#3182CE",
      700: "#2B6CB0",
      800: "#2C5282",
      900: "#2A4365",
    },
    indigo: {
      100: "#EBF4FF",
      200: "#C3DAFE",
      300: "#A3BFFA",
      400: "#7F9CF5",
      500: "#667EEA",
      600: "#5A67D8",
      700: "#4C51BF",
      800: "#434190",
      900: "#3C366B",
    },
    purple: {
      100: "#FAF5FF",
      200: "#E9D8FD",
      300: "#D6BCFA",
      400: "#B794F4",
      500: "#9F7AEA",
      600: "#805AD5",
      700: "#6B46C1",
      800: "#553C9A",
      900: "#44337A",
    },
    pink: {
      100: "#FFF5F7",
      200: "#FFF5F7",
      300: "#FBB6CE",
      400: "#F687B3",
      500: "#ED64A6",
      600: "#D53F8C",
      700: "#B83280",
      800: "#97266D",
      900: "#702459",
    },
  },
};

export default mainTheme;
