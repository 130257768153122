import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  MenuItem,
  useToast,
} from "@chakra-ui/react";

import { useFormik } from "formik";
import { useMutation, useQueryClient } from "react-query";
import * as Yup from "yup";

import UnpaidInvoicesTable from "../../tables/client/UnpaidInvoicesTable";

import { unpaidInvoiceClientReminder } from "../../../api/client/client";

const InvoiceReminderModal = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { client, invoices } = props;

  const queryClient = useQueryClient();

  const [selectedInvoices, setSelectedInvoices] = useState([]);

  const toast = useToast();

  const handleSelect = (e, id) => {
    const checked = e.target.checked;
    if (checked) {
      setSelectedInvoices((prevState) => [...prevState, id]);
    } else {
      setSelectedInvoices((prevState) => prevState.filter((ids) => ids !== id));
    }
  };

  const reminderFormik = useFormik({
    initialValues: {
      id: client.id,
      invoices: [],
    },
    onSubmit: (values) => {
      reminderMutation.mutate(values);
    },
  });

  const reminderMutation = useMutation(unpaidInvoiceClientReminder, {
    onSuccess: () => {
      toast({
        title: "Sukces!",
        position: "top-right",
        description: "Przypomnienie zostało wysłane!",
        status: "success",
        duration: 4000,
        isClosable: true,
      });

      queryClient.invalidateQueries("fetchClientChanges");
      onClose();
    },
    onError: () =>
      toast({
        title: "Uwaga, wystapił problem",
        position: "top-right",
        description: "Spróbuj ponownie później!",
        status: "error",
        duration: 4000,
        isClosable: true,
      }),
  });

  const handleSubmit = () => {
    reminderFormik.submitForm();
  };

  useEffect(() => {
    reminderFormik.setFieldValue("invoices", selectedInvoices);
  }, [selectedInvoices]);

  useEffect(() => {
    reminderFormik.resetForm();
    setSelectedInvoices([]);
  }, [isOpen]);

  return (
    <>
      <MenuItem onClick={onOpen}>Przypomnienie o płatności</MenuItem>

      <Modal isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Wyślij przypomnienie o płatności</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex d="column" w="full">
              <UnpaidInvoicesTable
                handleSelect={handleSelect}
                invoices={invoices.filter(
                  (invoice) => invoice.gross_amount > invoice.paid_amount
                )}
              />
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button size="sm" variant="ghost" mr={3} onClick={onClose}>
              Zamknij
            </Button>
            <Button
              size="sm"
              onClick={handleSubmit}
              isDisabled={selectedInvoices.length > 0 ? false : true}
              isLoading={reminderMutation.isLoading}
              colorScheme="green"
            >
              Wyślij przypomnienie
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default InvoiceReminderModal;
