import React from "react";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  Flex,
  Stack,
  Text,
  Badge,
  Center,
  useColorModeValue,
} from "@chakra-ui/react";
import { MdChevronRight } from "react-icons/md";

const MobileMenuLinks = (props) => {
  const { isBadge, isNotification, badgeInfo, href, icon, close } = props;
  const linkColor = useColorModeValue("gray.600", "white");
  const hoverColor = useColorModeValue("gray.800", "gray.500");

  return (
    <Box
      borderBottom="1px"
      borderColor="gray.400"
      w="full"
      mb="10px"
      p={isNotification ? "0px" : "10px"}
      py="14px"
      fontSize="12px"
      fontWeight="bold"
      color={props.location.pathname === href ? "green.500" : linkColor}
      _hover={{ color: hoverColor }}
      onClick={close}
    >
      <Link to={href}>
        <Flex cursor="pointer" w="full">
          <Stack isInline w="50%" justifyContent="flex-start">
            <Center>
              <Box as={icon} fontSize="xl" mb="4px" />
            </Center>
            <Text fontSize="md">{props.children}</Text>
          </Stack>
          <Flex w="50%" justifyContent="flex-end">
            {isBadge ? <Badge px="10px">{badgeInfo}</Badge> : false}
            <Box as={MdChevronRight} fontSize="xl" color={linkColor} />
          </Flex>
        </Flex>
      </Link>
    </Box>
  );
};

MobileMenuLinks.propTypes = {
  badgeInfo: PropTypes.string,
  href: PropTypes.string,
};

export default withRouter(MobileMenuLinks);
