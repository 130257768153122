import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";

import { useFormik } from "formik";
import { useMutation, useQueryClient } from "react-query";
import * as Yup from "yup";

import { storeClientTaxIdentificator } from "../../../api/client/client";

const CreateTaxIdentificatorModal = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { client_additional } = props;

  const queryClient = useQueryClient();

  const toast = useToast();

  const TaxSchema = Yup.object().shape({
    tax_id: Yup.string()
      .required("To pole jest wymagane!")
      .max(12, "Przekroczono liczbę znaków!")
      .min(10, "Za mała liczba znaków!"),
  });

  const taxFormik = useFormik({
    initialValues: {
      client_additional: client_additional,
      tax_id: "",
    },
    validationSchema: TaxSchema,
    onSubmit: (values) => {
      taxMutation.mutate(values);
    },
  });

  const taxMutation = useMutation(storeClientTaxIdentificator, {
    onSuccess: () => {
      toast({
        title: "Sukces!",
        position: "top-right",
        description: "Numer NIP został przypisany!",
        status: "success",
        duration: 4000,
        isClosable: true,
      });

      queryClient.invalidateQueries("fetchClientTaxIdentificators");
      onClose();
    },
    onError: () =>
      toast({
        title: "Uwaga, wystapił problem",
        position: "top-right",
        description: "Spróbuj ponownie później!",
        status: "error",
        duration: 4000,
        isClosable: true,
      }),
  });

  const handleSubmit = () => {
    taxFormik.submitForm();
  };

  useEffect(() => {
    taxFormik.resetForm();
  }, [isOpen]);

  return (
    <>
      <Button
        variant="ghost"
        color="green.500"
        size="xs"
        ml="auto"
        onClick={onOpen}
      >
        Dodaj
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Przypisz numer NIP</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex d="column" w="full">
              <form>
                <Flex direction="column" mb="2">
                  <FormControl id="year" isRequired>
                    <FormLabel>Numer NIP</FormLabel>
                    <Input
                      type="text"
                      name="tax_id"
                      onChange={taxFormik.handleChange}
                      value={taxFormik.values.tax_id}
                      placeholder="Wprowadź numer NIP"
                    />

                    {taxFormik.errors.tax_id && taxFormik.touched.tax_id && (
                      <Text mt="5px" color="red.500">
                        {taxFormik.errors.tax_id}
                      </Text>
                    )}
                  </FormControl>
                </Flex>
              </form>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button size="sm" variant="ghost" mr={3} onClick={onClose}>
              Zamknij
            </Button>
            <Button
              size="sm"
              onClick={handleSubmit}
              isLoading={taxMutation.isLoading}
              colorScheme="green"
            >
              Dodaj numer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateTaxIdentificatorModal;
