import React, { useEffect, useState } from "react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  useToast,
  Textarea,
  MenuItem,
} from "@chakra-ui/react";

import { useFormik } from "formik";
import { useMutation, useQueryClient } from "react-query";
import * as Yup from "yup";

import { updateClientDocument } from "../../../api/client/client";

const EditDocumentModal = (props) => {
  const { id, title, description } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();
  const queryClient = useQueryClient();

  const DocumentSchema = Yup.object().shape({
    title: Yup.string()
      .required("To pole jest wymagane!")
      .max(150, "To pole jest za długie!"),
    description: Yup.string().max(450, "To pole jest za długie!"),
  });

  const updateDocumentMutation = useMutation(updateClientDocument, {
    onSuccess: () => {
      toast({
        title: "Sukces!",
        position: "top-right",
        description: "Dokument został dodany do klienta!",
        status: "success",
        duration: 4000,
        isClosable: true,
      });

      onClose();
      queryClient.invalidateQueries("fetchClientDocuments");
    },
    onError: () =>
      toast({
        title: "Uwaga, wystapił problem",
        position: "top-right",
        description: "Spróbuj ponownie później!",
        status: "error",
        duration: 4000,
        isClosable: true,
      }),
  });

  const documentFormik = useFormik({
    initialValues: {
      id: id,
      title: title,
      description: description,
    },
    validationSchema: DocumentSchema,
    onSubmit: (values) => {
      updateDocumentMutation.mutate(values);
    },
  });

  const handleSubmit = () => {
    documentFormik.handleSubmit();
  };

  return (
    <>
      <MenuItem onClick={onOpen}>Edytuj</MenuItem>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edytuj dokument</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column" mb="2" mt="3">
              <FormControl id="title" isRequired>
                <FormLabel>Tytuł dokumentu</FormLabel>
                <Input
                  type="text"
                  onChange={documentFormik.handleChange}
                  value={documentFormik.values.title}
                  name="title"
                  placeholder="Wprowadź tytuł"
                />

                {documentFormik.errors.title && documentFormik.touched.title && (
                  <Text mt="5px" color="red.500">
                    {documentFormik.errors.title}
                  </Text>
                )}
              </FormControl>
            </Flex>

            <Flex direction="column" mb="2" mt="3">
              <FormControl id="description">
                <FormLabel>Opis dokumentu</FormLabel>
                <Textarea
                  onChange={documentFormik.handleChange}
                  value={documentFormik.values.description}
                  name="description"
                />

                {documentFormik.errors.description &&
                  documentFormik.touched.description && (
                    <Text mt="5px" color="red.500">
                      {documentFormik.errors.description}
                    </Text>
                  )}
              </FormControl>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button size="sm" variant="ghost" mr={3} onClick={onClose}>
              Zamknij
            </Button>
            <Button
              size="sm"
              onClick={handleSubmit}
              colorScheme="green"
              isLoading={updateDocumentMutation.isLoading}
            >
              Edytuj dokument
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditDocumentModal;
