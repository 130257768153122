import React from "react";

import NumberFormat from "react-number-format";
import { SimpleGrid, Flex, Text } from "@chakra-ui/react";

const ClientSummary = (props) => {
  const { clientFormik } = props;
  return (
    <Flex justify="center" p="35px">
      <SimpleGrid w="70%" spacingX="30px" spacingY="15px" columns="2">
        <Flex direction="column" mb="2">
          <Text fontWeight="semibold" color="gray.500">
            Zysk netto miesięcznie
          </Text>
          <Text fontSize="2xl">
            <NumberFormat
              value={
                clientFormik.values.price_per_unit *
                clientFormik.values.available_units
              }
              thousandSeparator={" "}
              decimalSeparator=","
              displayType={"text"}
              isNumericString="true"
              decimalScale="2"
              fixedDecimalScale={true}
            />{" "}
            zł
          </Text>
        </Flex>
        <Flex direction="column" mb="2">
          <Text fontWeight="semibold" color="gray.500">
            Zysk netto rocznie
          </Text>
          <Text fontSize="2xl">
            <NumberFormat
              value={
                clientFormik.values.price_per_unit *
                clientFormik.values.available_units *
                12
              }
              thousandSeparator={" "}
              decimalSeparator=","
              displayType={"text"}
              isNumericString="true"
              decimalScale="2"
              fixedDecimalScale={true}
            />{" "}
            zł
          </Text>
        </Flex>
        <Flex direction="column" mb="2">
          <Text fontWeight="semibold" color="gray.500">
            Zysk brutto miesięcznie
          </Text>
          <Text fontSize="2xl">
            <NumberFormat
              value={
                clientFormik.values.price_per_unit *
                  clientFormik.values.available_units +
                clientFormik.values.price_per_unit *
                  clientFormik.values.available_units *
                  0.23
              }
              thousandSeparator={" "}
              decimalSeparator=","
              displayType={"text"}
              isNumericString="true"
              decimalScale="2"
              fixedDecimalScale={true}
            />{" "}
            zł
          </Text>
        </Flex>
        <Flex direction="column" mb="2">
          <Text fontWeight="semibold" color="gray.500">
            Zysk brutto rocznie
          </Text>
          <Text fontSize="2xl">
            <NumberFormat
              value={
                (clientFormik.values.price_per_unit *
                  clientFormik.values.available_units +
                  clientFormik.values.price_per_unit *
                    clientFormik.values.available_units *
                    0.23) *
                12
              }
              thousandSeparator={" "}
              decimalSeparator=","
              displayType={"text"}
              isNumericString="true"
              decimalScale="2"
              fixedDecimalScale={true}
            />{" "}
            zł
          </Text>
        </Flex>
      </SimpleGrid>
    </Flex>
  );
};

export default ClientSummary;
