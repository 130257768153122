import React from "react";
import moment from "moment";

import { useTable, useSortBy, usePagination } from "react-table";

import {
  Flex,
  Button,
  Box,
  Text,
  Image,
  Input,
  Select,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { FiSearch, FiChevronUp, FiChevronDown } from "react-icons/fi";
import "moment/locale/pl";

const ClientTable = (props) => {
  const { clients } = props;

  const table = [];
  if (clients) {
    clients.map((d) => {
      table.push({
        id: d.id,
        name: d.company_name,
        demo: d.additional_details.test_until,
        schema: d.schema_name,
        preview: d.id,
        priority: d.additional_details.priority,
        contact: d.additional_details,
        invoice_date: d.additional_details.invoice_date,
        amount: d.available_units * d.additional_details.price_per_unit,
        is_active: d.is_active,
      });
    });
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Aktywny",
        accessor: "is_active",
        Cell: (row) => (
          <Flex>
            {row.value == true ? (
              <Box p="1" ml="2" borderRadius="lg" bg="green.200">
                <Text color="green.600" fontSize="xs" fontWeight="semibold">
                  Aktywny
                </Text>
              </Box>
            ) : (
              <Box p="1" ml="2" borderRadius="lg" bg="red.200">
                <Text color="red.600" fontSize="xs" fontWeight="semibold">
                  Nieaktywny
                </Text>
              </Box>
            )}
          </Flex>
        ),
      },
      {
        Header: "Nazwa",
        accessor: "name",
      },
      {
        Header: "Demo",
        accessor: "demo",
        Cell: (row) =>
          row.value &&
          moment(row.value) > moment() && (
            <Flex>
              <Box p="1" ml="2" borderRadius="lg" bg="blue.200">
                <Text color="blue.600" fontSize="xs" fontWeight="semibold">
                  {row.value}
                </Text>
              </Box>
            </Flex>
          ),
      },
      {
        Header: "Priorytet",
        accessor: "priority",
        Cell: (row) => (
          <Flex>
            {row.value == 3 && (
              <Box px="2" py="1" borderRadius="lg" bg="red.200">
                <Text color="red.600" fontSize="xs" fontWeight="semibold">
                  Ważny
                </Text>
              </Box>
            )}
            {row.value == 2 && (
              <Box px="2" py="1" borderRadius="lg" bg="orange.200">
                <Text color="orange.600" fontSize="xs" fontWeight="semibold">
                  Średni
                </Text>
              </Box>
            )}
            {row.value == 1 && (
              <Box px="2" py="1" borderRadius="lg" bg="green.200">
                <Text color="green.600" fontSize="xs" fontWeight="semibold">
                  Niski
                </Text>
              </Box>
            )}
          </Flex>
        ),
      },
      {
        Header: "Adres",
        accessor: "schema",
        Cell: (row) => <Text>https://{row.value}.mycheck.pl</Text>,
      },
      {
        Header: "Kontakt",
        accessor: "contact",
        Cell: (row) => (
          <Flex d="column">
            <Text lineHeight="1.4" mb="1">
              {row.value.contact_name}
            </Text>
            <Text lineHeight="1.4" fontSize="xs" color="gray.600">
              {row.value.contact_phone}
            </Text>
            <Text lineHeight="1.4" fontSize="xs" color="gray.600">
              {row.value.contact_place}
            </Text>
          </Flex>
        ),
      },
      {
        Header: "Data fakturowania",
        accessor: "invoice_date",
        Cell: (row) =>
          row.value && (
            <Flex d="column">
              <Text>
                {moment()
                  .startOf("month")
                  .add(row.value - 1, "days")
                  .format("YYYY-MM-DD")}
              </Text>
              <Text fontSize="xs" color="gray.600">
                {moment()
                  .locale("pl")
                  .to(
                    moment()
                      .startOf("month")
                      .add(row.value - 1, "days")
                  )}
              </Text>
            </Flex>
          ),
      },
      {
        Header: "Kwota netto ",
        accessor: "amount",
        Cell: (row) =>
          row.value && (
            <Flex d="column">
              <Text>{row.value}</Text>
            </Flex>
          ),
      },
      {
        Header: "Podgląd",
        accessor: "preview",
        Cell: (row) => (
          <Link to={`/klient/${row.value}`}>
            <Button>
              <Box as={FiSearch} />
            </Button>
          </Link>
        ),
      },
    ],
    []
  );

  const tableData = React.useMemo(() => table, [clients]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: tableData,
      initialState: {
        sortBy: [
          {
            id: "created_at",
            desc: true,
          },
        ],
        pageIndex: 0,
        pageSize: 25,
      },
    },
    useSortBy,
    usePagination
  );
  return (
    <Flex w="full" direction="column">
      <table {...getTableProps()} style={{ width: "100%" }}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  onClick={() => column.toggleSortBy(!column.isSortedDesc)}
                  key={column.render("Header")}
                  style={{
                    borderBottom: "solid 1px #cecece",
                    color: "#7f7f7f",
                    fontWeight: "bold",
                    paddingBottom: "15px",
                    fontSize: "13px",
                    cursor: "pointer",
                    textAlign: "left",
                  }}
                >
                  <Flex justify="flex-start">
                    <Text mr="2">{column.render("Header")}</Text>
                    <Text fontSize="15px">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <Box textColor="#2484ea" as={FiChevronDown}></Box>
                        ) : (
                          <Box textColor="#2484ea" as={FiChevronUp}></Box>
                        )
                      ) : (
                        ""
                      )}
                    </Text>
                  </Flex>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        padding: "10px",
                        borderBottom: "solid 1px #dedede",
                        fontSize: "14px",
                      }}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <Flex align="center" my="4">
        <Button mx="2" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </Button>{" "}
        <Button
          mx="2"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          {"<"}
        </Button>{" "}
        <Button mx="2" onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </Button>{" "}
        <Button
          mx="2"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          {">>"}
        </Button>{" "}
        <Text fontSize="sm">
          Strona{" "}
          <strong>
            {pageIndex + 1} z {pageOptions.length}
          </strong>{" "}
        </Text>
        <Text ml="4" fontSize="sm">
          Idź do strony:{" "}
        </Text>
        <Input
          type="number"
          defaultValue={pageIndex + 1}
          onChange={(e) => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0;
            gotoPage(page);
          }}
          size="xs"
          style={{ width: "100px" }}
          ml="2"
        />{" "}
        <Select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
          w="auto"
          ml="auto"
          size="sm"
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Pokaż {pageSize}
            </option>
          ))}
        </Select>
      </Flex>
    </Flex>
  );
};

export default ClientTable;
