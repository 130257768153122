import React from "react";
import { Route, Switch } from "react-router-dom";
import Hoc from "./hoc/hoc";
import PrivateRoute from "./auth.routes";

import Login from "./containers/Login";
import Signup from "./containers/Signup";
import Home from "./pages/Home";
import ClientCreate from "./pages/client/ClientCreate";
import ClientDetail from "./pages/client/ClientDetail";
import InvoicesList from "./pages/invoices/InvoicesList";
import Community from "./pages/Community";
import CommunityDetail from "./pages/community/CommunityDetail";

import Dashboard from "./containers/Dashboard";

const BaseRouter = (props) => (
  <Hoc>
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/signup" component={Signup} />
      <Dashboard {...props}>
        <Switch>
          <PrivateRoute path="/" component={Home} exact />
          <PrivateRoute path="/faktury" component={InvoicesList} />
          <PrivateRoute path="/klient/utworz" exact component={ClientCreate} />
          <PrivateRoute path="/klient/:id" exact component={ClientDetail} />
          <PrivateRoute path="/community" exact component={Community} />
          <PrivateRoute
            path="/community/baza/:id"
            exact
            component={CommunityDetail}
          />
        </Switch>
      </Dashboard>
    </Switch>
  </Hoc>
);

export default BaseRouter;
