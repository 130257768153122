import React, { useState } from "react";

import {
  Flex,
  Text,
  Input,
  FormControl,
  FormLabel,
  SimpleGrid,
} from "@chakra-ui/react";

const ClientInfo = (props) => {
  const { clientFormik } = props;

  return (
    <Flex justify="center" p="35px">
      <SimpleGrid w="70%" spacingX="30px" spacingY="15px" columns="2">
        <Flex direction="column" mb="2">
          <FormControl id="client_name" isRequired>
            <FormLabel fontWeight="semibold" fontSize="sm">
              Osoba kontaktowa
            </FormLabel>
            <Input
              type="text"
              name="client_name"
              placeholder="Wprowadź imię i nazwisko"
              onChange={clientFormik.handleChange}
              value={clientFormik.values.client_name}
            />

            {clientFormik.errors.client_name &&
              clientFormik.touched.client_name && (
                <Text mt="5px" color="red.500">
                  {clientFormik.errors.client_name}
                </Text>
              )}
          </FormControl>
        </Flex>
        <Flex direction="column" mb="2">
          <FormControl id="phone" isRequired>
            <FormLabel fontWeight="semibold" fontSize="sm">
              Numer telefonu
            </FormLabel>
            <Input
              type="text"
              name="phone"
              placeholder="Wprowadź numer telefonu"
              onChange={clientFormik.handleChange}
              value={clientFormik.values.phone}
            />

            {clientFormik.errors.phone && clientFormik.touched.phone && (
              <Text mt="5px" color="red.500">
                {clientFormik.errors.phone}
              </Text>
            )}
          </FormControl>
        </Flex>
        <Flex direction="column" mb="2">
          <FormControl id="email" isRequired>
            <FormLabel fontWeight="semibold" fontSize="sm">
              Adres e-mail
            </FormLabel>
            <Input
              type="email"
              name="email"
              placeholder="Wprowadź adres e-mail"
              onChange={clientFormik.handleChange}
              value={clientFormik.values.email}
            />

            {clientFormik.errors.email && clientFormik.touched.email && (
              <Text mt="5px" color="red.500">
                {clientFormik.errors.email}
              </Text>
            )}
          </FormControl>
        </Flex>
        <Flex direction="column" mb="2">
          <FormControl id="city" isRequired>
            <FormLabel fontWeight="semibold" fontSize="sm">
              Miasto
            </FormLabel>
            <Input
              type="text"
              name="city"
              placeholder="Wprowadź miasto"
              onChange={clientFormik.handleChange}
              value={clientFormik.values.city}
            />

            {clientFormik.errors.city && clientFormik.touched.city && (
              <Text mt="5px" color="red.500">
                {clientFormik.errors.city}
              </Text>
            )}
          </FormControl>
        </Flex>
        <Flex direction="column" mb="2">
          <FormControl id="nip" isRequired>
            <FormLabel fontWeight="semibold" fontSize="sm">
              Numer NIP
            </FormLabel>
            <Input
              type="text"
              name="nip"
              placeholder="Wprowadź numer NIP"
              onChange={clientFormik.handleChange}
              value={clientFormik.values.nip}
            />

            {clientFormik.errors.nip && clientFormik.touched.nip && (
              <Text mt="5px" color="red.500">
                {clientFormik.errors.nip}
              </Text>
            )}
          </FormControl>
        </Flex>
      </SimpleGrid>
    </Flex>
  );
};

export default ClientInfo;
