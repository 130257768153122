import React from "react";

import Sidebar from "../components/Sidebar/Sidebar";
import Navbar from "../components/Navbar";
import { Flex, Box } from "@chakra-ui/react";

import { connect } from "react-redux";
import { logout } from "../store/actions/auth";
import { Redirect } from "react-router";
import { useHistory } from "react-router-dom";

const Dashboard = (props) => {
  const { authenticated } = props;
  let history = useHistory();

  return (
    <>
      <Box d={["none", "none", "none", "block"]}>
        <Sidebar />
      </Box>
      <Flex
        ml={["none", "none", "none", "90px"]}
        minH="100vh"
        bg="primary.bg"
        direction="column"
      >
        <Navbar logout={authenticated ? props.logout : null} />
        <Flex px={["20px", "20px", "20px", "40px"]} py="25px">
          {props.children}
        </Flex>
      </Flex>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
