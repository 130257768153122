import React from "react";
import {
  Drawer,
  Box,
  Flex,
  Image,
  Text,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  useColorModeValue,
} from "@chakra-ui/react";

import MycheckLogo from "../../assets/images/mycheck.png";
import MobileMenuLink from "../Sidebar/MobileMenuLinks";

import { MdMenu } from "react-icons/md";
import { AiOutlineGlobal, AiOutlineAudit } from "react-icons/ai";
import {
  MdDashboard,
  MdHome,
  MdPlaylistAddCheck,
  MdEmail,
  MdContactMail,
  MdDateRange,
  MdPeople,
} from "react-icons/md";

import { FiHome, FiFile, FiLogOut } from "react-icons/fi";

const MobileMenu = (props) => {
  const { logout } = props;
  return (
    <Box cursor="pointer">
      <Menu logout={logout} />
    </Box>
  );
};
function Menu(props) {
  const { logout } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const secondColor = useColorModeValue("gray.700", "gray.600");

  const menu = [
    {
      icon: FiHome,
      href: "/",
      title: "Strona główna",
    },
    {
      icon: FiFile,
      href: "/faktury",
      title: "Faktury",
    },
  ];

  return (
    <>
      <Box
        cursor="pointer"
        as={MdMenu}
        onClick={onOpen}
        fontSize="3xl"
        color="gray.700"
      />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader></DrawerHeader>
          <DrawerBody>
            <Flex
              my="16px"
              w="full"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Box mt="10px">
                <Image h="40%" fallbackSrc={MycheckLogo} />
              </Box>
              <Flex mt="30px" w="full" flexDirection="column">
                {menu.map((m) => (
                  <MobileMenuLink
                    icon={m.icon}
                    close={onClose}
                    href={m.href}
                    key={m.title}
                  >
                    {m.title}
                  </MobileMenuLink>
                ))}
                <MobileMenuLink icon={FiLogOut} close={logout}>
                  Wyloguj się
                </MobileMenuLink>
              </Flex>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
export default MobileMenu;
