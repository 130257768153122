import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  Textarea,
  useToast,
  Tooltip,
  Box,
} from "@chakra-ui/react";

import { FiFolderPlus } from "react-icons/fi";

import { useFormik } from "formik";
import { useMutation, useQueryClient } from "react-query";
import * as Yup from "yup";

import { storeQuestionCategory } from "../../../api/community/question";

const CreateCategoryModal = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const queryClient = useQueryClient();
  const toast = useToast();

  const CategorySchema = Yup.object().shape({
    name: Yup.string()
      .required("To pole jest wymagane!")
      .max(150, "To pole jest za długie!"),
    description: Yup.string()
      .required("To pole jest wymagane!")
      .max(500, "To pole jest za długie!"),
  });

  const storeQuestionCategoryMutation = useMutation(storeQuestionCategory, {
    onSuccess: () => {
      toast({
        title: "Sukces!",
        position: "top-right",
        description: "Kategoria została utworzona!",
        status: "success",
        duration: 4000,
        isClosable: true,
      });

      onClose();
      categoryFormik.resetForm();
      queryClient.invalidateQueries("fetchCategories");
    },
    onError: () =>
      toast({
        title: "Uwaga, wystapił problem",
        position: "top-right",
        description: "Spróbuj ponownie później!",
        status: "error",
        duration: 4000,
        isClosable: true,
      }),
  });

  const categoryFormik = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema: CategorySchema,
    onSubmit: (values) => {
      console.log(values);
      storeQuestionCategoryMutation.mutate(values);
    },
  });

  return (
    <>
      <Tooltip label="Dodaj kategorię">
        <Button
          onClick={onOpen}
          variant="outline"
          size="sm"
          colorScheme="green"
        >
          <Box fontSize="lg" as={FiFolderPlus} />
        </Button>
      </Tooltip>
      <Modal isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Utwórz nową kategorię pytań</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex d="column" w="full">
              <Flex direction="column" mb="2" mt="3">
                <FormControl id="title" isRequired>
                  <FormLabel>Nazwa kategorii</FormLabel>
                  <Input
                    type="text"
                    onChange={categoryFormik.handleChange}
                    value={categoryFormik.values.name}
                    name="name"
                    placeholder="Wprowadź nazwę kategorii"
                  />

                  {categoryFormik.errors.name && categoryFormik.touched.name && (
                    <Text mt="5px" color="red.500">
                      {categoryFormik.errors.name}
                    </Text>
                  )}
                </FormControl>
              </Flex>
            </Flex>
            <Flex d="column" w="full">
              <Flex direction="column" mb="2" mt="3">
                <FormControl id="title" isRequired>
                  <FormLabel>Opis kategorii</FormLabel>
                  <Textarea
                    type="text"
                    onChange={categoryFormik.handleChange}
                    value={categoryFormik.values.description}
                    name="description"
                    placeholder="Wprowadź opis kategorii"
                  />

                  {categoryFormik.errors.description &&
                    categoryFormik.touched.description && (
                      <Text mt="5px" color="red.500">
                        {categoryFormik.errors.description}
                      </Text>
                    )}
                </FormControl>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button size="sm" variant="ghost" mr={3} onClick={onClose}>
              Zamknij
            </Button>
            <Button
              size="sm"
              colorScheme="green"
              isLoading={storeQuestionCategoryMutation.isLoading}
              onClick={() => categoryFormik.submitForm()}
            >
              Utwórz kategorię
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateCategoryModal;
