import React from "react";
import moment from "moment";
import NumberFormat from "react-number-format";

import { useTable, useSortBy } from "react-table";

import {
  Flex,
  Button,
  Box,
  Text,
  Checkbox,
  Input,
  Select,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { FiSearch, FiChevronUp, FiChevronDown } from "react-icons/fi";
import "moment/locale/pl";

const InvoicesClientTable = (props) => {
  const { invoices } = props;

  const table = [];
  if (invoices) {
    invoices.map((d) => {
      table.push({
        id: d.id,
        invoice_number: d.invoice_number,
        invoice_date: d.invoice_date,
        due_date: d.due_date,
        gross_amount: d.gross_amount,
        net_amount: d.net_amount,
        paid_amount: d.paid_amount,
      });
    });
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "Nr dokumentu",
        accessor: "invoice_number",
      },
      {
        Header: "Data wystawienia",
        accessor: "invoice_date",
      },

      {
        Header: "Termin płatności",
        accessor: "due_date",
      },
      {
        Header: "Kwota brutto",
        accessor: "gross_amount",
        Cell: (row) =>
          row.value && (
            <Flex justify="flex-end">
              <Text>
                <NumberFormat
                  value={row.value}
                  thousandSeparator={" "}
                  decimalSeparator=","
                  displayType={"text"}
                  isNumericString="true"
                />
              </Text>
            </Flex>
          ),
      },
      {
        Header: "Kwota netto",
        accessor: "net_amount",
        Cell: (row) =>
          row.value && (
            <Flex justify="flex-end">
              <Text>
                <NumberFormat
                  value={row.value}
                  thousandSeparator={" "}
                  decimalSeparator=","
                  displayType={"text"}
                  isNumericString="true"
                />
              </Text>
            </Flex>
          ),
      },
      {
        Header: "Kwota zapłaty",
        accessor: "paid_amount",
        Cell: (row) =>
          row.value && (
            <Flex justify="flex-end">
              {row.value < row.cell.row.original.gross_amount ? (
                <Text fontWeight="bold" fontSize="xs" color="red.500">
                  Niezapłacone
                </Text>
              ) : (
                <Text fontWeight="bold" fontSize="xs" color="green.500">
                  Zapłacone
                </Text>
              )}
            </Flex>
          ),
      },
    ],
    []
  );

  const tableData = React.useMemo(() => table, [invoices]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,

    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: tableData,
      initialState: {
        sortBy: [
          {
            id: "invoice_date",
            desc: true,
          },
        ],
      },
    },
    useSortBy
  );
  return (
    <Flex w="full" direction="column">
      <table {...getTableProps()} style={{ width: "100%" }}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  onClick={() => column.toggleSortBy(!column.isSortedDesc)}
                  key={column.render("Header")}
                  style={{
                    borderBottom: "solid 1px #cecece",
                    color: "#7f7f7f",
                    fontWeight: "bold",
                    paddingBottom: "15px",
                    fontSize: "13px",
                    cursor: "pointer",
                  }}
                >
                  <Flex justify="flex-start">
                    <Text mr="2">{column.render("Header")}</Text>
                    <Text fontSize="15px">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <Box textColor="#2484ea" as={FiChevronDown}></Box>
                        ) : (
                          <Box textColor="#2484ea" as={FiChevronUp}></Box>
                        )
                      ) : (
                        ""
                      )}
                    </Text>
                  </Flex>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        padding: "10px",
                        borderBottom: "solid 1px #dedede",
                        fontSize: "14px",
                      }}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Flex>
  );
};

export default InvoicesClientTable;
