import React, { useState } from "react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";

import { useFormik } from "formik";
import { useMutation, useQueryClient } from "react-query";
import * as Yup from "yup";

import { updateClientConfig } from "../../../api/client/client";

const EditConfigModal = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { client } = props;

  const toast = useToast();
  const queryClient = useQueryClient();

  const ConfigSchema = Yup.object().shape({
    available_checklists: Yup.number()
      .required("To pole jest wymagane!")
      .typeError("To pole musi być liczbą!"),
    available_users: Yup.number()
      .required("To pole jest wymagane!")
      .typeError("To pole musi być liczbą!"),
    available_report_emails: Yup.number()
      .required("To pole jest wymagane!")
      .typeError("To pole musi być liczbą!"),
    available_units: Yup.number()
      .required("To pole jest wymagane!")
      .typeError("To pole musi być liczbą!"),
    available_photo_quaility: Yup.number()
      .required("To pole jest wymagane!")
      .typeError("To pole musi być liczbą!"),
  });

  const configFormik = useFormik({
    initialValues: {
      id: client.id,
      available_checklists: client.available_checklists,
      available_users: client.available_users,
      available_report_emails: client.available_report_emails,
      available_units: client.available_units,
      available_photo_quaility: client.available_photo_quaility,
    },
    validationSchema: ConfigSchema,
    onSubmit: (values) => {
      updateConfigMutation.mutate(values);
    },
  });

  const updateConfigMutation = useMutation(updateClientConfig, {
    onSuccess: () => {
      toast({
        title: "Sukces!",
        position: "top-right",
        description: "Klient został poprawnie zaktualizowany!",
        status: "success",
        duration: 4000,
        isClosable: true,
      });

      queryClient.invalidateQueries("fetchClient");
      queryClient.invalidateQueries("fetchClientChanges");
      onClose();
    },
    onError: () =>
      toast({
        title: "Uwaga, wystapił problem",
        position: "top-right",
        description: "Spróbuj ponownie później!",
        status: "error",
        duration: 4000,
        isClosable: true,
      }),
  });

  const handleSubmit = () => {
    configFormik.submitForm();
  };

  return (
    <>
      <Button
        size="xs"
        ml="auto"
        colorScheme="blue"
        variant="ghost"
        onClick={onOpen}
      >
        Edytuj
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edytuj konfigurację klienta</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={configFormik.handleSubmit}>
              <Flex direction="column" mb="2">
                <FormControl id="year" isRequired>
                  <FormLabel>Dostępne checklisty</FormLabel>
                  <Input
                    type="text"
                    name="available_checklists"
                    onChange={configFormik.handleChange}
                    value={configFormik.values.available_checklists}
                    placeholder="Wprowadź rok"
                  />

                  {configFormik.errors.available_checklists &&
                    configFormik.touched.available_checklists && (
                      <Text mt="5px" color="red.500">
                        {configFormik.errors.available_checklists}
                      </Text>
                    )}
                </FormControl>
              </Flex>
              <Flex direction="column" mb="2">
                <FormControl id="year" isRequired>
                  <FormLabel>Dostępni użytkownicy</FormLabel>
                  <Input
                    type="text"
                    name="available_users"
                    onChange={configFormik.handleChange}
                    value={configFormik.values.available_users}
                    placeholder="Wprowadź rok"
                  />

                  {configFormik.errors.available_users &&
                    configFormik.touched.available_users && (
                      <Text mt="5px" color="red.500">
                        {configFormik.errors.available_users}
                      </Text>
                    )}
                </FormControl>
              </Flex>
              <Flex direction="column" mb="2">
                <FormControl id="available_report_emails" isRequired>
                  <FormLabel>Dostępne sloty email</FormLabel>
                  <Input
                    type="text"
                    name="available_report_emails"
                    onChange={configFormik.handleChange}
                    value={configFormik.values.available_report_emails}
                    placeholder="Wprowadź rok"
                  />

                  {configFormik.errors.available_report_emails &&
                    configFormik.touched.available_report_emails && (
                      <Text mt="5px" color="red.500">
                        {configFormik.errors.available_report_emails}
                      </Text>
                    )}
                </FormControl>
              </Flex>
              <Flex direction="column" mb="2">
                <FormControl id="available_units" isRequired>
                  <FormLabel>Dostępne jednostki</FormLabel>
                  <Input
                    type="text"
                    name="available_units"
                    onChange={configFormik.handleChange}
                    value={configFormik.values.available_units}
                    placeholder="Wprowadź rok"
                  />

                  {configFormik.errors.available_units &&
                    configFormik.touched.available_units && (
                      <Text mt="5px" color="red.500">
                        {configFormik.errors.available_units}
                      </Text>
                    )}
                </FormControl>
              </Flex>
              <Flex direction="column" mb="2">
                <FormControl id="available_photo_quaility" isRequired>
                  <FormLabel>Dostępna jakość zdjęć</FormLabel>
                  <Input
                    type="text"
                    name="available_photo_quaility"
                    onChange={configFormik.handleChange}
                    value={configFormik.values.available_photo_quaility}
                    placeholder="Wprowadź rok"
                  />

                  {configFormik.errors.available_photo_quaility &&
                    configFormik.touched.available_photo_quaility && (
                      <Text mt="5px" color="red.500">
                        {configFormik.errors.available_photo_quaility}
                      </Text>
                    )}
                </FormControl>
              </Flex>
            </form>
          </ModalBody>

          <ModalFooter>
            <Button size="sm" variant="ghost" mr={3} onClick={onClose}>
              Zamknij
            </Button>
            <Button size="sm" onClick={handleSubmit} colorScheme="green">
              Zapisz zmiany
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditConfigModal;
