import React from "react";
import moment from "moment";

import {
  Text,
  Flex,
  Link,
  Badge,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  Box,
} from "@chakra-ui/react";

import { GiHamburgerMenu } from "react-icons/gi";

import DeleteDocumentDialog from "../components/dialogs/DeleteDocumentDialog";
import EditDocumentModal from "../components/modals/client/EditDocumentModal";

const FileThumbnail = (props) => {
  const { id, title, url, created_at, description } = props;
  return (
    <Flex
      d="column"
      shadow="lg"
      p="5"
      justify="center"
      align="center"
      borderRadius="lg"
    >
      <Flex
        align="center"
        w="full"
        borderBottom="1px"
        borderColor="gray.300"
        pb="3"
      >
        <Text fontSize="xs" color="gray.600">
          {moment(created_at).format("YYYY-MM-DD H:m")}
        </Text>
        <Box ml="auto">
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<Box as={GiHamburgerMenu} />}
              variant="outline"
              size="sm"
            />
            <MenuList>
              <EditDocumentModal
                title={title}
                description={description}
                id={id}
              />
              <DeleteDocumentDialog id={id} />
            </MenuList>
          </Menu>
        </Box>
      </Flex>

      <Flex d="column" mt="4">
        <Link href={url} isExternal={true}>
          <Text textAlign="center">{title}</Text>
        </Link>
        <Text textAlign="center" fontSize="xs" color="gray.500">
          {description}
        </Text>

        <Flex justify="center" mt="3">
          <Badge colorScheme="blue">{url.split(".").pop()}</Badge>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FileThumbnail;
